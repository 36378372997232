import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '../../components/TextField';
import SectionWrapper2 from '../../components/SectionWrapper2';
import Button from '../../components/Button';
import StickyHeaderWrapper from '../../components/StickyHeaderWrapper';
import SelectField, { SelectFieldRef } from '../../components/SelectField';
import { default as gql } from 'graphql-tag';
import { createVouchers } from '../../utils/voucher';
import Dialog from '../../components/Dialog';
import { useQuery } from 'react-apollo';
import IntegerTextField from '../../components/IntegerTextField';
import { AllCompaniesQuery, Company } from '../../utils/company';
import DateSelectField from '../../components/DateSelectField';
import { addDayToDateString, dateToString } from '../../utils/time';
import { addYears } from 'date-fns';

const CSV_HEADERS = [
	{ label: 'QrCode', key: 'qrCode' },
	{ label: 'Gutscheintyp', key: 'voucherClass.title' },
	{ label: 'Erstellt von', key: 'user.name' },
	{ label: 'Gültig', key: 'valid' },
	{ label: 'Grund', key: 'reason' },
];

const CreateVoucherRender = () => {
	const [voucherType, setVoucherType] = React.useState<string>('');
	const [selectedCompany, setSelectedCompany] = React.useState<Company | null>(null);
	const [validUntil, setValidUntil] = useState<string | undefined>(
		() => dateToString(addYears(new Date(), 3))!
	);
	const minDate = useMemo(() => addDayToDateString(dateToString(new Date())), []);
	const [numberOfVouchers, setNumberOfVouchers] = React.useState<number | undefined>(1);
	const [reason, setReason] = React.useState<string>('');
	const [isLoading, setIsLoading] = React.useState(false);

	const submitEnabled = voucherType && (numberOfVouchers ?? 0) > 0;

	const selectRef = React.useRef<SelectFieldRef>(null);

	const handleVoucherTypeChange = React.useCallback((value: string | null) => {
		setVoucherType(value ?? '');
	}, []);

	const generateAndDownloadCSV = React.useCallback((data, filename, headers) => {
		// Generate CSV content
		const csvContent = [
			headers.map((header) => header.label).join(','),
			...data.map((item) =>
				headers
					.map((header) => {
						// Resolve nested properties
						const keys = header.key.split('.');
						let value = item;
						for (const key of keys) {
							value = value?.[key];
						}
						return value || '';
					})
					.join(',')
			),
		].join('\n');

		// Create a Blob for the CSV
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		const url = URL.createObjectURL(blob);

		// Trigger file download
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}, []);

	const handleCreateVoucher = React.useCallback(async () => {
		setIsLoading(true);
		const { success, error, vouchers } = await createVouchers(
			voucherType,
			reason,
			numberOfVouchers ?? 0,
			selectedCompany?.id,
			validUntil
		);
		setIsLoading(false);

		if (success) {
			// Call auxiliary function to generate and download the CSV
			generateAndDownloadCSV(vouchers, 'vouchers.csv', CSV_HEADERS);

			selectRef.current?.reset();
			setNumberOfVouchers(1);
			setReason('');

			Dialog.render({
				title: 'Gespeichert',
				description: 'Die Gutscheine wurden in die Datenbank eingetragen.',
				buttons: [
					{
						id: 'success',
						label: 'Weiter',
					},
				],
			});
		} else {
			Dialog.render({
				title: 'Error',
				description: error || 'Es gab einen Fehler beim Erstellen der Gutscheine.',
				buttons: [
					{
						id: 'error',
						label: 'Weiter',
					},
				],
			});
		}
	}, [generateAndDownloadCSV, numberOfVouchers, reason, voucherType]);

	const { data: { getAvailableVoucherClasses: voucherClasses = [] } = {} } = useQuery(
		gql`
			query getAvailableVoucherClasses {
				getAvailableVoucherClasses {
					id
					title
				}
			}
		`
	);

	const { data: { companies = [] } = {} } = useQuery<{ companies: Company[] }>(AllCompaniesQuery);

	return (
		<Box width="100%">
			<SectionWrapper2 label="Neuer Gutschein" p="2rem">
				<Box flexDirection="column" display="flex" gap="2rem" width="100%">
					<SelectField
						ref={selectRef}
						label="Gutscheinart"
						items={voucherClasses.map((vc) => ({
							value: vc.id,
							label: vc.title,
						}))}
						onChange={handleVoucherTypeChange}
					/>
					<SelectField
						label="Unternehmen"
						key={selectedCompany?.id}
						defaultValue={selectedCompany?.id}
						items={companies.map((c) => ({
							value: c.id,
							label: c.name,
						}))}
						onChange={(id) => {
							setSelectedCompany(id ? companies.find((c) => c.id === id) ?? null : null);
						}}
					/>
					<DateSelectField
						label="Gültig bis"
						onChange={setValidUntil}
						defaultDate={validUntil}
						variant={'outlined'}
						flex
						minDate={minDate}
					/>
					<IntegerTextField
						defaultValue={numberOfVouchers ?? 0}
						onChange={setNumberOfVouchers}
						label="Anzahl Gutscheine"
						width="100%"
					/>
					<TextField
						name="reason"
						value={reason}
						onChange={setReason}
						placeholder="Kommentar / Grund der Beanstandung"
						label="Kommentar / Grund der Beanstandung"
						fullWidth
						width="100%"
					/>
				</Box>

				<Button
					m="2rem 0 0 0"
					onClick={handleCreateVoucher}
					label={'Gutscheine erstellen'}
					disabled={!submitEnabled}
					loading={isLoading}
					variant="outlined"
				/>
			</SectionWrapper2>
		</Box>
	);
};

const CreateVoucher: React.FC = () => {
	return (
		<StickyHeaderWrapper label={`Gutscheine erstellen`} maxContentWidth="130rem">
			<CreateVoucherRender />
		</StickyHeaderWrapper>
	);
};
export default CreateVoucher;
