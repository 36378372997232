import gql from 'graphql-tag';

export type Company = {
	id: string;
	name: string;
	logo: string;
};

export const CompanyFragment = gql`
	fragment companyFragment on Company {
		id
		name
		logo
	}
`;

export const SingleCompanyQuery = gql`
	query SingleCompany($id: ID!) {
		company(id: $id) {
			...companyFragment
		}
	}
	${CompanyFragment}
`;

export const AllCompaniesQuery = gql`
	query AllCompanies {
		companies {
			...companyFragment
		}
	}
	${CompanyFragment}
`;

export const EditCompanyMutation = gql`
	mutation EditCompany($id: ID!, $name: String!, $logo: String!) {
		editCompany(id: $id, name: $name, logo: $logo) {
			...companyFragment
		}
	}
	${CompanyFragment}
`;

export const DeleteCompaniesMutation = gql`
	mutation DeleteCompanies($ids: [ID!]!) {
		deleteCompanies(ids: $ids)
	}
`;

export const CreateCompanyMutation = gql`
	mutation CreateCompany($name: String!, $logo: String!) {
		createCompany(name: $name, logo: $logo) {
			...companyFragment
		}
	}
	${CompanyFragment}
`;
