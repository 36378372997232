import { default as gql } from 'graphql-tag';
import client from '../apollo';
import { useQuery } from 'react-apollo';
import { ApolloError } from 'apollo-client';
import React from 'react';
import { Company } from './company';

export const VoucherInstanceFragment = gql`
	fragment VoucherInstanceFragment on VoucherInstance {
		id
		user {
			email
			fullName
		}
		voucherClass {
			id
			title
		}
		company {
			id
			name
			logo
		}
		reason
		valid
		qrCode
		redeemedDatetime
		createdDatetime
	}
`;

export const createVouchers = async (
	voucherClassId: string,
	reason: string | null,
	numberOfVouchers: number,
	companyId?: string,
	validUntil?: string
): Promise<
	| { success: true; error: null; vouchers: VoucherInstance[] }
	| { success: false; error: 'NETWORK_ERROR' | string; vouchers: null }
> => {
	try {
		const { data } = await client.mutate({
			mutation: gql`
				mutation CreateVouchers(
					$voucherClassId: ID!
					$companyId: ID
					$numberOfVouchers: Int!
					$reason: String
					$validUntil: Date
				) {
					createVoucherInstances(
						voucherClassId: $voucherClassId
						companyId: $companyId
						numberOfVouchers: $numberOfVouchers
						reason: $reason
						validUntil: $validUntil
					) {
						...VoucherInstanceFragment
					}
				}
				${VoucherInstanceFragment}
			`,
			variables: { voucherClassId, numberOfVouchers, reason, companyId, validUntil },
		});

		const vouchers = data.createVoucherInstances;

		return { success: true, error: null, vouchers };
	} catch (e: any) {
		if (e.networkError) {
			return { success: false, error: 'NETWORK_ERROR', vouchers: null };
		} else {
			throw e;
		}
	}
};

export type VoucherInstance = {
	id: string;
	qrCode: string | null;
	createdDatetime: string;
	redeemedDatetime: string | null;
	valid: boolean;
	reason: string | null;
	company?: Company;
	voucherClass: {
		id: string;
		title: string;
	};
	user?: {
		email: string;
		fullName: string;
	};
};

export const useSearchVoucherInstanceByQR = (
	queryText: string
): { voucher: VoucherInstance | null; error?: ApolloError; loading: boolean } => {
	const { data, loading } = useQuery(
		gql`
			query GetVoucherInstance($qrCode: String!) {
				voucherInstanceByQR(qrCode: $qrCode) {
					...VoucherInstanceFragment
				}
			}
			${VoucherInstanceFragment}
		`,
		{
			variables: { qrCode: queryText },
			skip: !queryText.trim(),
		}
	);

	return React.useMemo(
		() => ({
			voucher: data?.voucherInstanceByQR || null,
			loading,
		}),
		[data?.voucherInstanceByQR, loading]
	);
};

export const useSearchVoucherInstancesByOrderNumber = (
	queryText: string
): { vouchers: VoucherInstance[]; error?: ApolloError; loading: boolean } => {
	const { data, loading } = useQuery(
		gql`
			query GetVoucherInstancesByOrderNumber($orderNumber: String!) {
				voucherInstancesByOrderNumber(orderNumber: $orderNumber) {
					...VoucherInstanceFragment
				}
			}
			${VoucherInstanceFragment}
		`,
		{
			variables: { orderNumber: queryText },
			skip: !queryText.trim(),
		}
	);

	return React.useMemo(
		() => ({
			vouchers: data?.voucherInstancesByOrderNumber || [],
			loading,
		}),
		[data?.voucherInstancesByOrderNumber, loading]
	);
};

export const updateVoucherInstance = async (
	voucherId: string,
	isValid: boolean
): Promise<
	| { success: true; error: null; voucher?: VoucherInstance }
	| { success: false; error: 'NETWORK_ERROR' | string; voucher?: VoucherInstance }
> => {
	try {
		const { data } = await client.mutate({
			mutation: gql`
				mutation UpdateVoucherInstance($id: ID!, $isValid: Boolean!) {
					updateVoucherInstance(id: $id, isValid: $isValid) {
						...VoucherInstanceFragment
					}
				}
				${VoucherInstanceFragment}
			`,
			variables: { id: voucherId, isValid },
		});

		const result = data?.updateVoucherInstance;

		return {
			success: true,
			error: null,
			voucher: result.voucherInstance,
		};
	} catch (e: any) {
		if (e.networkError) {
			return { success: false, error: 'NETWORK_ERROR', voucher: undefined };
		} else {
			throw e;
		}
	}
};
