import gql from 'graphql-tag';
import { ID } from '@cinuru/utils/types';
import client from '../apollo';

const TicketDetails = gql`
	fragment TicketDetails on Ticket {
		id
		screening {
			id
			movie {
				id
				title
				poster
			}
			datetime
			cinema {
				name
			}
		}
		boughtAt
		status
		onlineTicketingId
		isFlatrateTicket
		qrCode
		auditoriumName
		seats {
			seatName
			rowName
		}
		scanned
	}
`;

export const updateTicket = async ({
	id,
	scanned,
}: {
	id: ID;
	scanned?: boolean;
}): Promise<{ success: true; error: undefined } | { success: false; error?: string }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation UpdateTicket($id: ID!, $scanned: Boolean!) {
					updateTicket(id: $id, scanned: $scanned) {
						...TicketDetails
					}
				}
				${TicketDetails}
			`,
			variables: {
				id,
				scanned,
			},
			errorPolicy: 'all',
		});
		if (errors) {
			return { success: false, error: errors[0]?.message };
		} else return { success: true, error: undefined };
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		else throw e;
	}
};
