import React from 'react';
import { useParams, useHistory } from 'react-router';
import Box from '@mui/material/Box';
import TextField from '../../components/TextField';
import SectionWrapper2 from '../../components/SectionWrapper2';
import Txt from '../../components/Txt';
import StickyHeaderWrapper from '../../components/StickyHeaderWrapper';

import { Beacon, updateBeacon, createBeacon, useBeaconById } from '../../utils/beacon';
import CinemaSelectFieldNew from '../../components/CinemaSelectFieldNew';
import { ID } from 'utils/types';
import Button from '../../components/Button';
import { useSnackbar } from 'notistack';
import { DatePicker } from '@mui/lab';

const EditBeaconRender = ({ beacon, isNew }: { beacon?: Beacon; isNew: boolean }) => {
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const [loading, setLoading] = React.useState(false);
	const [formState, setFormState] = React.useState({
		cinemaId: beacon?.cinema?.id ?? '',
		major: beacon?.major ?? '',
		minor: beacon?.minor ?? '',
		comments: beacon?.comments ?? '',
		lastBatteryChange: beacon?.lastBatteryChange ? new Date(beacon.lastBatteryChange) : null,
	});

	const handleInputChange = React.useCallback(
		(name: string) => (value: string) => {
			setFormState((prevState) => ({
				...prevState,
				[name]: value,
			}));
		},
		[]
	);

	const handleDateChange = React.useCallback((date: Date | null) => {
		setFormState((prevState) => ({
			...prevState,
			lastBatteryChange: date ?? null,
		}));
	}, []);

	const handleSelectCinemaId = React.useCallback((items: { id: ID; name: string }[]) => {
		const newCinemaId = items[0]?.id;
		setFormState((prevState) => ({
			...prevState,
			cinemaId: String(newCinemaId),
		}));
	}, []);

	const defaultIds = React.useMemo(() => [formState.cinemaId], [formState.cinemaId]);

	const handleSaveBeacon = React.useCallback(async () => {
		setLoading(true);
		let result;

		if (isNew) {
			result = await createBeacon({
				...formState,
				major: Number(formState.major),
				minor: Number(formState.minor),
				lastBatteryChange: formState.lastBatteryChange || undefined,
			});
		} else {
			result = await updateBeacon({
				beaconId: beacon!.id,
				...formState,
				major: Number(formState.major),
				minor: Number(formState.minor),
				lastBatteryChange: formState.lastBatteryChange || undefined,
				lastScan: beacon?.lastScan,
			});
		}

		if (result.success) {
			enqueueSnackbar(isNew ? 'Beacon erfolgreich erstellt' : 'Beacon erfolgreich aktualisiert', {
				variant: 'success',
			});
			history.goBack();
		} else {
			enqueueSnackbar(
				`Fehler beim ${isNew ? 'Erstellen' : 'Aktualisieren'} des Beacons: ${
					result.error ? result.error : 'Unbekannte Fehler'
				}`,
				{ variant: 'error' }
			);
		}
		setLoading(false);
	}, [isNew, beacon, formState, enqueueSnackbar, history]);

	const renderInput = React.useCallback((props) => <TextField {...props} fullWidth />, []);

	const submitEnabled = formState.cinemaId && formState.major && formState.minor;

	return (
		<Box width="100%">
			<SectionWrapper2 p="2rem">
				<Box flexDirection="column" display="flex" gap="2rem" width="100%">
					<CinemaSelectFieldNew
						label="Kino auswählen"
						onChange={handleSelectCinemaId}
						defaultIds={defaultIds}
					/>
					<TextField
						name="major"
						value={formState.major}
						onChange={handleInputChange('major')}
						placeholder="1001"
						label="Major"
						fullWidth
					/>
					<TextField
						name="minor"
						value={formState.minor}
						onChange={handleInputChange('minor')}
						placeholder="1"
						label="Minor"
						fullWidth
					/>
					<TextField
						name="comments"
						value={formState.comments}
						onChange={handleInputChange('comments')}
						placeholder="z.B. links der Einganstür von Kino 3"
						label="Hinweise / Ort"
						fullWidth
					/>
					<DatePicker
						label="Letzter Batteriewechsel"
						value={formState.lastBatteryChange}
						onChange={handleDateChange}
						inputFormat="dd.MM.yyyy"
						renderInput={renderInput}
						mask="__.__.____"
					/>
					<Button
						m="2rem 0 0 0"
						onClick={handleSaveBeacon}
						label={isNew ? 'Erstellen' : 'Speichern'}
						loading={loading}
						variant="contained"
						disabled={!submitEnabled}
					/>
				</Box>
			</SectionWrapper2>
		</Box>
	);
};

const EditBeacon: React.FC = () => {
	const { id } = useParams<{ id?: string }>();
	const isNew = !id;
	const { data, error, loading } = useBeaconById(id as ID);

	return (
		<StickyHeaderWrapper
			label={isNew ? 'Beacon erstellen' : 'Beacon bearbeiten'}
			maxContentWidth="130rem"
			isLoading={!isNew && loading}
		>
			{error ? (
				<Txt color="error">{error.message}</Txt>
			) : (
				<EditBeaconRender beacon={isNew ? undefined : data} isNew={isNew} />
			)}
		</StickyHeaderWrapper>
	);
};
export default EditBeacon;
