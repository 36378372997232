import React, { useCallback } from 'react';
import Table from '../../components/Table';
import SectionWrapper2 from '../../components/SectionWrapper2';
import LoadingBox from '../../components/LoadingBox';
import StickyHeaderWrapper, { IconButtonIconName } from '../../components/StickyHeaderWrapper';
import { deleteBeacon, useBeacons } from '../../utils/beacon';
import { orderBy } from 'lodash';
import { ID } from 'utils/types';
import { useHistory } from 'react-router';
import { ButtonIconName } from '../../components/StickyHeaderBar';
import Dialog from '../../components/Dialog';
import { useSnackbar } from 'notistack';
import Txt from '../../components/Txt';

const columns = [
	'Kino',
	'Major',
	'Minor',
	'Hinweise / Ort',
	'Letzter Scan',
	'Letzter Batteriewechsel',
	'',
	'',
].map((label, idx) => ({ label, id: idx }));

const BeaconDetails: React.FC = () => {
	const history = useHistory();
	const { beacons, loading, refetch } = useBeacons();
	const { enqueueSnackbar } = useSnackbar();

	const handleEditBeacon = useCallback(
		(id: ID) => () => {
			history.push(`/admin/beacons/details/${id}`);
		},
		[history]
	);

	const handleCreateBeacon = useCallback(() => {
		history.push(`/admin/beacons/new`);
	}, [history]);

	const deleteBeaconHandler = useCallback(
		async (beaconId) => {
			const result = await deleteBeacon(beaconId);
			if (result.success) {
				enqueueSnackbar('Beacon erfolgreich gelöscht', {
					variant: 'success',
				});
				refetch();
			} else {
				enqueueSnackbar(
					`Fehler beim Löschen des Beacons: ${result.error ? result.error : 'Unbekannte Fehler'}`,
					{ variant: 'error' }
				);
			}
		},
		[refetch, enqueueSnackbar]
	);

	const handleDeleteBeacon = React.useCallback(
		(beaconId: ID) => async () => {
			Dialog.render({
				title: 'Beacon löschen',
				description: `Möchten Sie den Beacon wirklich löschen?`,
				buttons: [
					{
						id: `cancel-button-1-${beaconId}`,
						label: 'Abbrechen',
					},
					{
						id: `cancel-button-2-${beaconId}`,
						label: 'Beacon löschen',
						onClick: () => deleteBeaconHandler(beaconId),
					},
				],
			});
		},
		[deleteBeaconHandler]
	);

	const rows = React.useMemo(
		() =>
			orderBy(
				beacons.map((beacon) => ({
					id: beacon.id,
					rawData: beacon,
					cinema: beacon.cinema.name,
					data: [
						{
							text: String(beacon.cinema.name),
						},
						{ text: String(beacon.major) },
						{ text: String(beacon.minor) },
						{ text: beacon.comments ? String(beacon.comments) : '-' },
						{
							text: beacon.lastScan
								? new Date(beacon.lastScan).toLocaleString('de-DE', {
										dateStyle: 'long',
										timeStyle: 'short',
								  })
								: '-',
						},
						{
							text: beacon.lastBatteryChange
								? new Date(beacon.lastBatteryChange).toLocaleString('de-DE', {
										dateStyle: 'long',
								  })
								: '-',
						},
						{
							buttonLabel: 'Bearbeiten',
							onPress: handleEditBeacon(beacon.id),
						},
						{
							buttonLabel: 'Löschen',
							onPress: handleDeleteBeacon(beacon.id),
						},
					],
				})),
				['cinema'],
				['asc']
			),
		[beacons, handleEditBeacon, handleDeleteBeacon]
	);

	const buttons: {
		label: string;
		startIconName: ButtonIconName;
		onClick: () => void;
		collapsedIconName: IconButtonIconName;
	}[] = React.useMemo(
		() => [
			{
				label: 'Beacon erstellen',
				startIconName: 'Add',
				onClick: handleCreateBeacon,
				collapsedIconName: 'Add',
			},
		],
		[handleCreateBeacon]
	);

	return (
		<StickyHeaderWrapper label="Beacons" maxContentWidth="80%" buttons={buttons}>
			<SectionWrapper2 p="3rem">
				{loading ? (
					<LoadingBox />
				) : !rows?.length ? (
					<Txt>Keine Beacons vorhanden. Erstellen Sie einen neuen Beacon, um zu starten.</Txt>
				) : (
					<Table
						columns={columns}
						label={'Beacons'}
						allRows={rows}
						isSelectable={false}
						isSearchable={false}
					/>
				)}
			</SectionWrapper2>
		</StickyHeaderWrapper>
	);
};

export default BeaconDetails;
