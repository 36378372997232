import React from 'react';
import Table from '../../components/Table';
import SectionWrapper2 from '../../components/SectionWrapper2';
import TextField from '../../components/TextField';
import Txt from '../../components/Txt';
import { useDebouncedSearchText } from '../../utils/search';
import LoadingBox from '../../components/LoadingBox';
import StickyHeaderWrapper from '../../components/StickyHeaderWrapper';
import { useSearchVoucherInstancesByOrderNumber } from '../../utils/voucher';

const columns = [
	'Gutscheincode',
	'Gutscheintyp',
	'Eingelöst durch Nutzer',
	'Gültig',
	'Grund',
	'',
].map((label, id) => ({
	label,
	id,
}));

const ShopifyOrders: React.FC = () => {
	const { queryText, setQueryText } = useDebouncedSearchText();
	const { vouchers, error, loading } = useSearchVoucherInstancesByOrderNumber(queryText);

	const rows = React.useMemo(() => {
		return vouchers.map((voucher) => ({
			id: voucher.id,
			rawData: voucher,
			data: [
				{
					text: voucher.qrCode,
					onPress: () => {
						window.location.href = `/gutscheine/details?search=${voucher.qrCode}`;
					},
				},
				{
					text: voucher.voucherClass.title,
				},
				{
					text: voucher.user
						? `${voucher.user.fullName} - (
					${voucher.user.email})`
						: '',
				},
				{
					text: voucher.valid ? 'Ja' : voucher.redeemedDatetime ? 'Eingelöst' : 'Nein',
				},
				{
					text: voucher.reason || '-',
				},
			],
		}));
	}, [vouchers]);

	return (
		<StickyHeaderWrapper label="Shopify bestellungen" maxContentWidth="130rem">
			<SectionWrapper2 p="3rem">
				<TextField type="text" label="Bestellnummer" autoFocus key={queryText} defaultValue={queryText} onChange={setQueryText} />
				{loading ? (
					<LoadingBox />
				) : error ? (
					<Txt color="error">{error.message}</Txt>
				) : !rows?.length ? null : (
					<Table
						columns={columns}
						label={'Benutzer'}
						allRows={rows}
						isSelectable={false}
						isSearchable={false}
					/>
				)}
			</SectionWrapper2>
		</StickyHeaderWrapper>
	);
};

export default ShopifyOrders;
