import React from 'react';
import { useQuery } from 'react-apollo';
import { default as gql } from 'graphql-tag';
import { omit } from 'lodash';

import type { ID } from '@cinuru/utils/types';

import client from '../apollo';

const CinemaDetailsFragment = gql`
	fragment CinemaDetailsFragment on Cinema {
		id
		name
		logo
		claim
		hasBonusProgram
		headerImage
		imprint
		technologies
		history
		currentInformation
		specialAboutUs
		onlineTicketingBaseUrl
		street
		houseNumber
		zipCode
		city
		parkingDescription
		locationDescription
		location {
			latitude
			longitude
		}
		googleMapsLink
		appleMapsLink
		phone
		facebook
		twitter
		website
		email
		instagram
		barrierFree
		barrierFreeText
		hearingImpaired
		hearingImpairedText
		brands
		blind
		blindText
		openingHoursDisplay {
			weekdays
			hours
		}
		pricesDisplay {
			title
			price
			description
		}
		specialOffersDisplay {
			title
			image
			description
			price
		}
		giftVouchersDisplay {
			title
			image
			description
			price
		}
		sneaksDisplay {
			description
			title
			price
			image
		}
		accessRestrictedTo
	}
`;

const omitTypeName = (array) => {
	return array.map((object) => omit(object, '__typename'));
};

export const useCinemaDetails = (id?: ID): Cinema => {
	const { data, loading } = useQuery(
		gql`
			query CinemaDetails($cinemaId: ID!) {
				cinema(id: $cinemaId) {
					...CinemaDetailsFragment
				}
			}
			${CinemaDetailsFragment}
		`,
		{
			fetchPolicy: 'cache-and-network',
			variables: { cinemaId: id },
			skip: !id,
		}
	);

	return React.useMemo(
		() =>
			!loading && data?.cinema
				? {
						...data.cinema,
						openingHoursDisplay: omitTypeName(data.cinema.openingHoursDisplay),
						sneaksDisplay: omitTypeName(data.cinema.sneaksDisplay),
						pricesDisplay: omitTypeName(data.cinema.pricesDisplay),
						specialOffersDisplay: omitTypeName(data.cinema.specialOffersDisplay),
						giftVouchersDisplay: omitTypeName(data.cinema.giftVouchersDisplay),
				  }
				: undefined,
		[data?.cinema, loading]
	);
};

type Brand =
	| 'CINURU'
	| 'CINFINITY'
	| 'CINEPLEX'
	| 'CINEWEB'
	| 'LUMOS'
	| 'ELSELICHTSPIELE'
	| 'DREHWERK';

type AccessRestriction = 'PRODUCTION' | 'STAGING' | 'DEVELOPMENT';

type OpeningHours = {
	weekdays?: string;
	hours?: string;
};

type CinemaInformationParagraph = {
	title?: string;
	image?: string;
	description?: string;
	price?: string;
};

export type Cinema = {
	id: string;
	name?: string;
	logo?: string;
	claim?: string;
	hasBonusProgram?: boolean;
	headerImage?: string;
	imprint?: string;
	technologies?: string;
	history?: string;
	currentInformation?: string;
	specialAboutUs?: string;
	onlineTicketingBaseUrl?: string;
	street?: string;
	houseNumber?: string;
	zipCode?: string;
	city?: string;
	parkingDescription?: string;
	locationDescription?: string;
	googleMapsLink?: string;
	appleMapsLink?: string;
	phone?: string;
	facebook?: string;
	twitter?: string;
	website?: string;
	email?: string;
	instagram?: string;
	barrierFree?: boolean;
	barrierFreeText?: string;
	hearingImpaired?: boolean;
	hearingImpairedText?: string;
	blind?: boolean;
	blindText?: string;
	brands: Brand[];
	openingHoursDisplay: OpeningHours[];
	pricesDisplay: CinemaInformationParagraph[];
	specialOffersDisplay: CinemaInformationParagraph[];
	giftVouchersDisplay: CinemaInformationParagraph[];
	sneaksDisplay: CinemaInformationParagraph[];
	accessRestrictedTo: AccessRestriction;
	location?: {
		latitude?: number;
		longitude?: number;
	};
};

export const saveCinema = async (
	cinema: Cinema
): Promise<
	{ success: true; error: null } | { success: false; error: 'NETWORK_ERROR' | string }
> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation updateCinema(
					$id: ID!
					$name: String!
					$logo: String
					$claim: String
					$hasBonusProgram: Boolean
					$headerImage: String
					$imprint: String
					$technologies: String
					$history: String
					$currentInformation: String
					$specialAboutUs: String
					$onlineTicketingBaseUrl: String
					$street: String
					$houseNumber: String
					$zipCode: String
					$city: String
					$parkingDescription: String
					$locationDescription: String
					$googleMapsLink: String
					$appleMapsLink: String
					$phone: String
					$facebook: String
					$twitter: String
					$website: String
					$email: String
					$instagram: String
					$barrierFree: Boolean
					$barrierFreeText: String
					$hearingImpaired: Boolean
					$hearingImpairedText: String
					$blind: Boolean
					$blindText: String
					$brands: [Brand!]!
					$openingHoursDisplay: [CinemaOpeningHoursInput!]!
					$pricesDisplay: [CinemaInformationParagraphInput!]!
					$specialOffersDisplay: [CinemaInformationParagraphInput!]!
					$giftVouchersDisplay: [CinemaInformationParagraphInput!]!
					$sneaksDisplay: [CinemaInformationParagraphInput!]!
					$accessRestrictedTo: AccessRestriction!
					$location: CinemaLocationInput
				) {
					updateCinema(
						cinema: {
							id: $id
							name: $name
							logo: $logo
							claim: $claim
							hasBonusProgram: $hasBonusProgram
							headerImage: $headerImage
							imprint: $imprint
							technologies: $technologies
							history: $history
							specialAboutUs: $specialAboutUs
							currentInformation: $currentInformation
							onlineTicketingBaseUrl: $onlineTicketingBaseUrl
							street: $street
							houseNumber: $houseNumber
							zipCode: $zipCode
							city: $city
							parkingDescription: $parkingDescription
							locationDescription: $locationDescription
							googleMapsLink: $googleMapsLink
							appleMapsLink: $appleMapsLink
							phone: $phone
							facebook: $facebook
							twitter: $twitter
							instagram: $instagram
							website: $website
							email: $email
							barrierFree: $barrierFree
							barrierFreeText: $barrierFreeText
							hearingImpaired: $hearingImpaired
							hearingImpairedText: $hearingImpairedText
							blind: $blind
							blindText: $blindText
							openingHoursDisplay: $openingHoursDisplay
							pricesDisplay: $pricesDisplay
							specialOffersDisplay: $specialOffersDisplay
							giftVouchersDisplay: $giftVouchersDisplay
							sneaksDisplay: $sneaksDisplay
							brands: $brands
							accessRestrictedTo: $accessRestrictedTo
							location: $location
						}
					) {
						success
						cinema {
							...CinemaDetailsFragment
						}
					}
				}
				${CinemaDetailsFragment}
			`,
			variables: cinema,
		});
		if (errors) {
			const error = errors[0].message;
			return { success: false, error };
		} else return { success: true, error: null };
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		else throw e;
	}
};

export const createCinema = async (): Promise<
	| { success: true; error: null; id: ID }
	| { success: false; error: 'NETWORK_ERROR' | string; id: null }
> => {
	try {
		const { data, errors } = await client.mutate({
			mutation: gql`
				mutation createCinema {
					createCinema {
						success
						cinema {
							...CinemaDetailsFragment
						}
					}
				}
				${CinemaDetailsFragment}
			`,
			refetchQueries: ['Privileges'],
		});
		if (errors) {
			const error = errors[0]!.message;
			return { success: false, error, id: null };
		} else return { success: true, error: null, id: data.createCinema.cinema.id };
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR', id: null };
		else throw e;
	}
};

export const useCinemaIds = (): ID[] | undefined => {
	const userRes = useQuery(gql`
		query {
			currentUser {
				id
				privileges {
					adminForCinemas {
						id
						name
					}
				}
			}
		}
	`);
	return React.useMemo(
		() => userRes.data?.currentUser?.privileges?.adminForCinemas?.map((c) => c.id) || [],
		[userRes]
	);
};
