/** may need some refactoring, no native support */

// @flow
import * as React from 'react';
import { debounce } from 'lodash';
import shortid from 'shortid';
import { Text } from 'react-native';
import styled from 'styled-native-components';
import styledWeb from 'styled-components';
import {
	Editor,
	EditorState,
	RichUtils,
	CompositeDecorator,
	SelectionState,
	ContentState,
	Modifier,
	getDefaultKeyBinding,
	KeyBindingUtil,
} from 'draft-js';
import { stateToMarkdown } from 'draft-js-export-markdown';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { Map } from 'immutable';
import { smartypantsu } from 'smartypants';

import {
	BodyParagraph,
	HeadingParagraph,
	SubHeadingParagraph,
	QuoteParagraph,
	ListParagraph,
	BulletListItem,
	NumberedListItem,
	BorderParagraph,
	ImageParagraph,
} from '../Article';
import Paragraph from '../Paragraph';
import BaseField from '../BaseField';
import PortalTrigger from '../PortalTrigger';
import DropDown from '../DropDown';
import Touchable from '../Touchable';

import ControlStrip from './ControlStrip';
import Link from './Link';

// fix for https://github.com/facebook/draft-js/issues/1188#issuecomment-319353638
const _addRange = Selection.prototype.addRange;
Selection.prototype.addRange = function () {
	_addRange.apply(this, arguments);
	if (this.rangeCount === 0) _addRange.apply(this, arguments);
};

const parseCustomBlocks = (node) => {
	if (
		node.tagName === 'P' &&
		node.childNodes &&
		node.childNodes.length === 1 &&
		smartypantsu(node.childNodes[0].toString(), -1).match(/^\s*[-]{3,}\s*$/)
	) {
		return { type: 'atomic', data: { type: 'horizontal-ruler' } };
	}
	let imgNode;
	if (node.tagName === 'IMG') imgNode = node;
	if (node.childNodes) imgNode = node.childNodes.find((n) => n.tagName === 'IMG');
	if (imgNode) {
		return { type: 'atomic', data: { type: 'image' } };
	}
};

const EditorWrapper = styledWeb.div`
	min-height: ${(p) => p.theme.rem * p.numberOfLines * 3}px;
	width: 100%;
	margin-top: ${(p) => p.theme.rem * (p.markdown ? 1.5 : 0)}px;
	.DraftEditor-root {
		${(p) => (p.alternativeStyle ? `padding-right: 2rem;` : `line-height: ${(p) => p.theme.rem * 3}px`)}
	};
`;

const NoMarginWrapper = styledWeb.div`
	margin: 0 ${(p) => p.theme.rem * -1.5}px;
`;

const VariableWrapper = styled(Touchable).attrs((p) => ({
	onPress: () => p.onInsert && p.onInsert(p.variable),
}))`
	cursor: pointer;
	margin-right: ${(p) => p.theme.rem}px;
	margin-bottom: ${(p) => p.theme.rem / 2}px;
`;

const Variable = styled(
	// eslint-disable-next-line no-unused-vars
	({ offsetKey, entityKey, blockKey, decoratedText, contentState, ...props }) => <Text {...props} />
)`
	border-style: solid;
	border-color: ${(p) =>
		p.theme.colors.blend(0.1, p.theme.colors.accent0, p.theme.colors.neutral0)};
	border-width: ${(p) => p.theme.rem / 8}px;
	background-color: ${(p) => p.theme.colors.transparentize(0.85, p.theme.colors.accent0)};
	padding: 0 ${(p) => p.theme.rem / 2}px ${(p) => p.theme.rem / 6}px;
	border-radius: ${(p) => p.theme.rem / 2}px;
	color: ${(p) => p.theme.colors.blend(0.1, p.theme.colors.accent0, p.theme.colors.neutral0)};
	font-weight: 500;
`;

const VariablePickerLabel = styled(Paragraph)`
	font-weight: 700;
	color: $neutral2;
	margin-right: 1rem;
`;

const INPUT_MARGINS = [0];

const linkStrategy = (contentBlock, callback, contentState) =>
	contentBlock.findEntityRanges((character) => {
		const entityKey = character.getEntity();
		return entityKey && contentState.getEntity(entityKey).getType() === 'LINK';
	}, callback);

const variableStrategy = (contentBlock, callback, contentState) =>
	contentBlock.findEntityRanges((character) => {
		const entityKey = character.getEntity();
		return entityKey && contentState.getEntity(entityKey).getType() === 'VARIABLE';
	}, callback);

const textBlockRenderMap = Map({
	unstyled: { element: Paragraph },
});

const alternativeStyleMap = Map({
	'unstyled': { element: (data) => BodyParagraph({ size: 's', children: data.children }) },
	'header-one': { element: (data) => HeadingParagraph({ size: 'l', children: data.children }) },
	'header-two': {
		element: (data) => SubHeadingParagraph({ size: 'm', children: data.children }),
	},
	'blockquote': { element: (data) => QuoteParagraph({ size: 's', children: data.children }) },
	'ordered-list-item': {
		element: (data) => NumberedListItem({ size: 's', children: data.children }),
		wrapper: <ListParagraph />,
	},
	'unordered-list-item': {
		element: (data) => BulletListItem({ size: 's', children: data.children }),
		wrapper: <ListParagraph />,
	},
});

const standardStyleMap = Map({
	'unstyled': { element: BodyParagraph },
	'header-one': { element: HeadingParagraph },
	'header-two': { element: SubHeadingParagraph },
	'blockquote': { element: QuoteParagraph },
	'ordered-list-item': {
		element: NumberedListItem,
		wrapper: <ListParagraph />,
	},
	'unordered-list-item': {
		element: BulletListItem,
		wrapper: <ListParagraph />,
	},
});

const AtomicBlock = React.memo(({ block, contentState }) => {
	switch (block.getData().get('type')) {
		case 'horizontal-ruler':
			return (
				<div contentEditable={false}>
					<BorderParagraph />
				</div>
			);
		case 'image': {
			const entity = block.getEntityAt(0) && contentState.getEntity(block.getEntityAt(0));
			return (
				<NoMarginWrapper contentEditable={false}>
					<ImageParagraph
						src={entity.getData().src}
						caption={entity.getData().alt}
						negativeMargin={1.5}
					/>
				</NoMarginWrapper>
			);
		}
		default:
			return null;
	}
});

const blockRendererFn = (contentBlock) => {
	if (contentBlock.getType() === 'atomic') {
		return {
			component: AtomicBlock,
			editable: false,
		};
	}
};

const keyBindingFn = (e) => {
	if (e.nativeEvent.key === 'k' && KeyBindingUtil.hasCommandModifier(e)) {
		return 'insert-link';
	}
	return getDefaultKeyBinding(e);
};

const insertVariableEntity = (contentState, selectionState, variable) => {
	if (!variable) return contentState;
	contentState = contentState.createEntity('VARIABLE', 'IMMUTABLE', variable);
	const entityKey = contentState.getLastCreatedEntityKey();
	const text = variable.label.replace(/-/g, '\u2011').replace(/ /g, '\u00A0');
	contentState = Modifier.replaceText(contentState, selectionState, text, null, entityKey);
	return contentState;
};

const valueFromEditorState = (editorState, variables, markdown) => {
	let contentState = editorState.getCurrentContent();
	if (variables) {
		contentState.getBlockMap().forEach((block) => {
			// eslint-disable-next-line no-constant-condition
			while (true) {
				const chars = block.getCharacterList();
				const start = chars.findIndex(
					(char) => char.getEntity() && contentState.getEntity(char.getEntity()).type === 'VARIABLE'
				);
				if (start < 0) break;
				const entityKey = block.getEntityAt(start);
				const end = chars.findLastIndex((char) => char.getEntity() === entityKey) + 1;
				const entity = contentState.getEntity(entityKey);
				const variable = variables.find((v) => entity.getData().name === v.name);
				let selectionState = SelectionState.createEmpty(block.getKey());
				selectionState = selectionState.merge({ anchorOffset: start, focusOffset: end });
				contentState = Modifier.replaceText(contentState, selectionState, `{${variable.name}}`);
				block = contentState.getBlockForKey(block.getKey());
			}
		});
	}
	return smartypantsu(
		markdown ? stateToMarkdown(contentState).replace(/\u200B/g, '') : contentState.getPlainText()
	);
};

type Props = {
	label: string,
	id?: string,
	hint?: string,
	error?: string,
	characterLimit?: number,
	value?: string,
	markdown?: boolean,
	variables?: { name: string, label: string, inputLength?: number }[],
	numberOfLines?: number,
	nMargins?: number[],
	flex?: boolean,
	width?: string,
	altBackground?: boolean,
	borderWidth?: string,
	padding?: number,
	onChange?: (string) => any,
	onFocus?: () => any,
	onBlur?: () => any,
	noClear?: boolean,
	alternativeStyle?: boolean,
	focusStyle?: 'underline' | 'outline' | 'disabled',
	disableInitialHandleChange?: boolean,
};
type State = {
	editorState: EditorState,
	selected: boolean,
	staySelected: boolean,
};
export default class EditorField extends React.PureComponent<Props, State> {
	static defaultProps = {
		nMargins: [1, 0],
		inputChangeDebounce: 334,
		numberOfLines: 3,
		value: '',
		alternativeStyle: false,
	};
	inputRef = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			editorState: this.editorStateFromValue(props.value, props.variables, props.markdown),
			selected: false,
			staySelected: false,
			inputLength: (props.value && props.value.length) || 0,
		};
		this.debouncedHandleChange = debounce(this.handleChange, props.inputChangeDebounce);
	}

	componentDidMount = () => (this.props?.disableInitialHandleChange ? null : this.handleChange());

	componentDidUpdate = (prevProps) => {
		if (prevProps.id !== this.props.id && prevProps.value !== this.props.value) {
			this.setState({
				editorState: this.editorStateFromValue(
					this.props.value,
					this.props.variables,
					this.props.markdown
				),
				selected: false,
				staySelected: false,
				inputLength: (this.props.value && this.props.value.length) || 0,
			});
		}
	};

	componentWillUnmount = () => this.debouncedHandleChange.cancel();

	handleFocus = () =>
		this.setState({ selected: true }, () => this.props.onFocus && this.props.onFocus());
	handleBlur = () =>
		!this.state.staySelected &&
		this.setState({ selected: false }, () => this.props.onBlur && this.props.onBlur());

	handleSelect = () => this.inputRef.current && this.inputRef.current.focus();

	handleClear = () => {
		this.setState({ editorState: this.editorStateFromValue('', this.props.variables) });
		this.props.onChange && this.props.onChange('');
	};

	handleChange = () => {
		const { variables, markdown } = this.props;

		const value = valueFromEditorState(this.state.editorState, variables, markdown);
		// remove trailing new line character '\n' from string if present
		const trailingNewLineCharacter = JSON.stringify(value.slice(-1)) === JSON.stringify('\n');
		const sanitizedValue = trailingNewLineCharacter
			? value === '\n'
				? ''
				: value.slice(0, -1)
			: value;
		this.setState({ inputLength: this.getInputLength(sanitizedValue || '') });
		this.props.onChange && this.props.onChange(sanitizedValue);
	};

	getInputLength = (text) => {
		let length = this.state.editorState.getCurrentContent().getPlainText().length;
		if (this.props.variables) {
			for (const match of text.matchAll(/\{[^}]*\}/g)) {
				const name = match[0].replace(/[{}]/g, '');
				const variable = this.props.variables.find((v) => name === v.name);
				if (variable.inputLength) {
					length -= variable.label.length;
					length += variable.inputLength;
				}
			}
		}
		return length;
	};

	handleEditorState = (editorState) => {
		this.setState({ editorState });
		this.debouncedHandleChange();
	};

	linkStateKey = shortid.generate();
	editorStateFromValue = (value, variables, markdown) => {
		value = smartypantsu(value || '');
		let contentState = markdown
			? stateFromMarkdown(value, { customBlockFn: parseCustomBlocks })
			: ContentState.createFromText(value);
		if (variables) {
			contentState.getBlockMap().forEach((block) => {
				// eslint-disable-next-line no-constant-condition
				while (true) {
					const text = block.getText();
					const match = text.match(/\{[^}]*\}/);
					if (!match) break;
					const start = match.index;
					const end = start + match[0].length;
					const name = match[0].replace(/[{}]/g, '');
					const variable = variables.find((v) => name === v.name);
					let selectionState = SelectionState.createEmpty(block.getKey());
					selectionState = selectionState.merge({ anchorOffset: start, focusOffset: end });
					contentState = insertVariableEntity(contentState, selectionState, variable);
					block = contentState.getBlockForKey(block.getKey());
				}
			});
		}
		const decorator = new CompositeDecorator([
			{
				strategy: linkStrategy,
				component: (props) => (
					<Link
						{...props}
						// somehow the key for links get's set wrong so we will jusr force rerender the link
						key={this.linkStateKey + props.offsetKey + props.blockKey}
						onEditEntity={this.handleEditEntity}
						onRemoveEntity={this.handleToggleEntity}
						onClosePopup={this.handleLinkEditDismissed}
						onOpenPopup={this.handleLinkEditMounted}
					/>
				),
			},
			{ strategy: variableStrategy, component: Variable },
		]);
		const editorState = EditorState.createWithContent(contentState, decorator);

		return editorState;
	};

	handleLinkEditMounted = () => this.setState({ staySelected: true });
	handleLinkEditDismissed = () => {
		this.setState({ staySelected: false });
		let editorState = this.state.editorState;
		const selectionState = editorState.getSelection();
		editorState = EditorState.forceSelection(editorState, selectionState);
		this.handleEditorState(editorState);
	};

	handleInsertVariable = (variable) => {
		this.handleFocus();
		let contentState = this.state.editorState.getCurrentContent();
		const selectionState = this.state.editorState.getSelection();
		contentState = insertVariableEntity(contentState, selectionState, variable);
		// const cursor = selectionState.getFocusOffset() + variable.label.length;
		// selectionState = selectionState.merge({ anchorOffset: cursor, focusOffset: cursor });
		// contentState = Modifier.insertText(contentState, selectionState, '');
		this.handleEditorState(EditorState.push(this.state.editorState, contentState, 'apply-entity'));
	};

	handleToggleInlineStyle = (inlineStyle) =>
		this.props.markdown &&
		this.handleEditorState(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));

	handleToggleBlockType = (blockType) =>
		this.props.markdown &&
		this.handleEditorState(RichUtils.toggleBlockType(this.state.editorState, blockType));

	handleInsertAtomicBlock = (dataType, text, entity) => {
		let editorState = this.state.editorState;
		if (this.props.markdown) {
			let contentState = editorState.getCurrentContent();
			let selectionState = editorState.getSelection();
			let currentBlock = contentState.getBlockForKey(selectionState.getStartKey());
			// if current block is not empty, split it,
			if (currentBlock.getText().trim()) {
				contentState = Modifier.splitBlock(contentState, selectionState);
				// if new block is not empty, split it again
				selectionState = contentState.getSelectionAfter();
				currentBlock = contentState.getBlockForKey(selectionState.getStartKey());
				if (currentBlock.getText().trim()) {
					contentState = Modifier.splitBlock(contentState, selectionState);
				}
			}
			// set block type of new block and add data and entity if provided
			contentState = Modifier.setBlockType(contentState, selectionState, 'atomic');
			contentState = Modifier.setBlockData(contentState, selectionState, { type: dataType });
			if (text) {
				contentState = Modifier.insertText(contentState, selectionState, text);
			}
			if (entity) {
				contentState = contentState.createEntity(entity.type, 'IMMUTABLE', entity.data);
				const entityKey = contentState.getLastCreatedEntityKey();
				selectionState = selectionState.merge({ focusOffset: text.length });
				contentState = Modifier.applyEntity(contentState, selectionState, entityKey);
			}
			// move selection after inserted block
			const newBlock = contentState.getBlockForKey(selectionState.getStartKey());
			const nextBlock = contentState.getBlockAfter(newBlock.getKey());
			if (nextBlock) {
				selectionState = selectionState.merge({ anchorKey: nextBlock.getKey(), anchorOffset: 0 });
			} else {
				// if inserted section is last, add another unstyled one to be selected
				selectionState = selectionState.merge({
					anchorOffset: newBlock.size,
					focusOffset: newBlock.size,
				});
				contentState = Modifier.splitBlock(contentState, selectionState);
				selectionState = contentState.getSelectionAfter();
				contentState = Modifier.setBlockType(contentState, selectionState, 'unstyled');
			}
			// update editor state
			editorState = EditorState.push(editorState, contentState, 'insert-block');
			editorState = EditorState.forceSelection(editorState, selectionState);
			this.handleEditorState(editorState);
		}
	};

	handleEditEntity = (entityKey, data) => {
		const editorState = this.state.editorState;
		let contentState = editorState.getCurrentContent();
		contentState = contentState.mergeEntityData(entityKey, data);
		this.handleEditorState(EditorState.push(editorState, contentState, 'edit-entity'));
	};

	handleToggleEntity = (type, mutability, data) => {
		if (this.props.markdown) {
			const editorState = this.state.editorState;
			let contentState = editorState.getCurrentContent();
			let selectionState = editorState.getSelection();
			const currentBlock = contentState.getBlockForKey(selectionState.getAnchorKey());
			// if selection state is collapsed, select word that cursor is in
			if (selectionState.isCollapsed()) {
				const words = currentBlock.text.split(' ');
				let [i, wordOffset] = [0, 0];
				while (wordOffset + words[i].length + 1 <= selectionState.getAnchorOffset()) {
					wordOffset += words[i++].length + 1;
				}
				selectionState = selectionState.merge({
					anchorOffset: wordOffset,
					focusOffset: wordOffset + words[i].length,
				});
			}
			// the rendering of links gets all mixed up, so we force rerender all links when adding/removing one
			this.linkStateKey = shortid.generate();

			let entityKey = currentBlock.getEntityAt(selectionState.getAnchorOffset());
			if (entityKey) {
				// if entiy already exists, remove it
				this.handleEditorState(RichUtils.toggleLink(editorState, selectionState, null));
			} else {
				// else add new entity
				contentState = contentState.createEntity(type, mutability, data);
				entityKey = contentState.getLastCreatedEntityKey();
				this.handleEditorState(RichUtils.toggleLink(editorState, selectionState, entityKey));
			}
		}
	};

	handleRemoveEntity = () => {};

	handleKeyCommand = (command, editorState) => {
		if (this.props.markdown) {
			if (command === 'insert-link') {
				this.handleToggleEntity('LINK', 'MUTABLE', {});
				return 'handled';
			} else {
				const newState = RichUtils.handleKeyCommand(editorState, command);
				if (newState) this.handleEditorState(newState);
				return newState ? 'handled' : 'not-handled';
			}
		}
	};

	renderOverlay = () => {
		const { altBackground, variables } = this.props;
		const error = this.state.error || this.props.error;
		return variables && variables.length ? (
			<DropDown
				focusline
				hasError={Boolean(error)}
				color={altBackground ? '$background0' : '$background1'}
				borderColor="$border0"
				padding="2rem 1.5rem 1.5rem"
				flexFlow="row wrap"
			>
				<VariablePickerLabel>Verfügbare Variablen:</VariablePickerLabel>
				{variables.map((variable) => (
					<VariableWrapper
						key={variable.name}
						variable={variable}
						onInsert={this.handleInsertVariable}
					>
						<Variable>{variable.label}</Variable>
					</VariableWrapper>
				))}
			</DropDown>
		) : null;
	};

	render = () => {
		const {
			nMargins,
			label,
			numberOfLines,
			variables,
			flex,
			hint,
			characterLimit,
			altBackground,
			error,
			markdown,
			width,
			borderWidth,
			padding,
			noClear,
			alternativeStyle,
			focusStyle,
		} = this.props;
		const { editorState, selected, inputLength } = this.state;
		const activeInlineStyles = this.state.editorState.getCurrentInlineStyle();
		const activeBlockType = this.state.editorState
			.getCurrentContent()
			.getBlockForKey(this.state.editorState.getSelection().getStartKey())
			.getType();
		const field = (
			<BaseField
				multiline
				label={label}
				hint={hint}
				characterLimit={characterLimit}
				nMargins={variables ? INPUT_MARGINS : nMargins}
				selected={selected}
				inputLength={inputLength}
				onClick={this.handleSelect}
				onClear={this.handleClear}
				altBackground={altBackground}
				error={error}
				width={width}
				borderWidth={borderWidth}
				padding={padding}
				noClear={noClear}
				focusStyle={focusStyle}
			>
				<EditorWrapper
					numberOfLines={numberOfLines}
					markdown={markdown}
					alternativeStyle={alternativeStyle}
				>
					<Editor
						ref={this.inputRef}
						editorState={editorState}
						onChange={this.handleEditorState}
						keyBindingFn={keyBindingFn}
						handleKeyCommand={this.handleKeyCommand}
						blockRenderMap={
							markdown
								? alternativeStyle
									? alternativeStyleMap
									: standardStyleMap
								: textBlockRenderMap
						}
						blockRendererFn={blockRendererFn}
						onFocus={this.handleFocus}
						onBlur={this.handleBlur}
					/>
				</EditorWrapper>
				{markdown ? (
					<ControlStrip
						activeInlineStyles={activeInlineStyles}
						activeBlockType={activeBlockType}
						onToggleInlineStyle={this.handleToggleInlineStyle}
						onToggleBlockType={this.handleToggleBlockType}
						onInsertBlock={this.handleInsertAtomicBlock}
						onToggleEntity={this.handleToggleEntity}
					/>
				) : null}
			</BaseField>
		);
		return variables ? (
			<PortalTrigger
				margin={nMargins.join('rem ') + 'rem'}
				flexBase={flex && '30rem'}
				active={selected}
				contentTopMargin={hint || error ? '-3.25rem' : '-0.25rem'}
				stretch
				renderOverlay={this.renderOverlay}
			>
				{field}
			</PortalTrigger>
		) : (
			field
		);
	};
}
