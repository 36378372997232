import gql from 'graphql-tag';
import { Company, CompanyFragment } from './company';

export const SubscriptionDetailFragment = gql`
	fragment SubscriptionDetailFragment on Subscription {
		id
		subscriptionTier {
			id
			name
			description
			details
		}
		company {
			...companyFragment
		}
		validFrom
		payedUntil
		blocked
		active
		canceled
		blockedReason
		blockedText
		paypalOrderId
		paypalStatus
		cancellationEffectiveDate
	}
	${CompanyFragment}
`;

export type Subscription = {
	id: string;
	subscriptionTier: {
		id: string;
		name: string;
		description: string;
		details: string;
	};
	validFrom: string;
	payedUntil: string;
	blocked: boolean;
	active: boolean;
	canceled: boolean;
	blockedReason: string;
	blockedText: string;
	company_id?: string;
	company?: Company;
	paypalOrderId: string;
	paypalStatus:
		| 'APPROVAL_PENDING'
		| 'APPROVED'
		| 'ACTIVE'
		| 'SUSPENDED'
		| 'CANCELLED'
		| 'EXPIRED'
		| null
		| 'INVALID_RESOURCE_ID';
	cancellationEffectiveDate: string;
};
