import { ID } from '@cinuru/utils/types';
import { TextFieldProps } from '@mui/material/TextField';
import * as React from 'react';

import { useUserPrivileges } from '../utils/user';
import SearchSelectField, { SelectFieldItem } from './SearchSelectField';
import TextField from './TextField';

export type CinemaSelectFieldNewRef = {
	validate: () => boolean;
};

const CinemaSelectFieldNew2 = React.forwardRef<
	CinemaSelectFieldNewRef,
	{
		label: string;
		onChange?: (movies: { id: ID; name: string }[]) => void;
		multi?: boolean;
		variant?: TextFieldProps['variant'];
		defaultIds?: ID[] | null;
		defaultMovieIds?: ID[];
		m?: string;
		disabled?: boolean;
		width?: string;
		onCinemasFetched?: (cinemas: { id: ID; name: string; accessRestrictedTo: string }[]) => void;
	}
>(
	(
		{
			label,
			onChange,
			multi,
			variant = 'outlined',
			defaultIds,
			m,
			disabled,
			width,
			onCinemasFetched,
		},
		ref
	): JSX.Element => {
		const viewerPrivileges = useUserPrivileges();
		const availableCinemas = viewerPrivileges?.adminForCinemas;

		React.useEffect(() => {
			if (availableCinemas && onCinemasFetched) {
				const transformed = availableCinemas.map((cinema) => ({
					id: cinema.id as ID,
					name: cinema.name,
					accessRestrictedTo: cinema.accessRestrictedTo,
				}));
				onCinemasFetched(transformed);
			}
		}, [availableCinemas, onCinemasFetched]);

		const handleChange = React.useCallback(
			(items: SelectFieldItem[]) => {
				const transformed = items.map((item) => ({ id: item.value as ID, name: item.label }));
				onChange?.(transformed);
			},
			[onChange]
		);

		const transformed = React.useMemo(
			() =>
				availableCinemas?.map((cinema) => ({
					label: `${cinema.name} - ${cinema.city}`,
					value: cinema.id as string,
				})),
			[availableCinemas]
		);

		const defaultItems = React.useMemo(() => {
			return availableCinemas
				?.filter(({ id }) => defaultIds?.includes(id))
				.map(({ id, name }) => ({ label: name, value: id as string }));
		}, [availableCinemas, defaultIds]);

		return !availableCinemas ? (
			<TextField label={label} variant={variant} m={m} isLoading />
		) : (
			<SearchSelectField
				allItems={transformed}
				label={label}
				onChange={handleChange}
				multi={multi}
				variant={variant}
				defaultItems={defaultItems}
				m={m}
				ref={ref}
				disabled={disabled}
				width={width}
			/>
		);
	}
);

export default CinemaSelectFieldNew2;
