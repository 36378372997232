import gql from 'graphql-tag';
import { CinemaOperatingCompany, CinemaOperatingCompanyFragment } from '../../utils/user';

export const CreateCinemaOperatingCompany = gql`
	mutation CreateCinemaOperatingCompany($data: CinemaOperatingCompanyData!) {
		createCinemaOperatingCompany(data: $data) {
			id
		}
	}
`;

export const UpdateCinemaOperatingCompany = gql`
	mutation UpdateCinemaOperatingCompany($id: ID!, $data: CinemaOperatingCompanyData!) {
		updateCinemaOperatingCompany(id: $id, data: $data) {
			...cinemaOperatingCompanyFragment
		}
	}
	${CinemaOperatingCompanyFragment}
`;

export const AllCinemasQuery = gql`
	query AllCinemas {
		cinemas {
			id
			name
		}
	}
`;

export type TargetGroupFilterConfig = {
	name: string;
	showFilter: boolean;
};

export type CinemaOperatingCompanyInput = {
	name: string;
	targetGroupFilterConfig: TargetGroupFilterConfig[];
	accessRightDashboard: boolean;
	accessRightFilmStatistics: boolean;
	accessRightBonusProgram: boolean;
	accessRightCampaigning: boolean;
	externalPhoneNumber?: string;
	pricingLevel?: string;
	pricingScheme?: string;
	cinemasIds: string[];
};
