import React, { Component } from 'react';

import styled from 'styled-native-components';

import BonusprogramSelectField from '../components/BonusprogramSelectField';

import { Button, TextField, EditorField, Dialog, Heading } from '@cinuru/components';

import { withApollo } from 'react-apollo';
import { default as gql } from 'graphql-tag';

import WithData from '../components/WithData';

const Wrapper = styled.View`
	background-color: $background0;
	padding: 3rem 4rem;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	elevation: 2;
	margin-bottom: 4rem;
`;

const ButtonWrapper = styled.View`
	flex-flow: row;
	justify-content: flex-end;
`;

const TEXT_FIELD_MARGINS = [2, -0.5];
const processTermsLink = (termsLink, bonusProgramId) =>
	termsLink === 'https://cinuru.com/bonusterms/' + bonusProgramId ? '' : termsLink;

class EditBonusprogram extends Component {
	//userPrivilegeTableKey is used to rerender the privileges table if necessary
	state = { selectedBonusProgramId: null };

	handleSelectBonusprograms = (selectedBonusProgramId) => {
		this.setState({
			selectedBonusProgramId,
			bpInitialized: false,
			name: null,
			termsLink: null,
			legalEntity: null,
		});
	};
	initializeBonusProgramState = (bonusProgram) => {
		this.setState({
			selectedBonusProgramId: bonusProgram.id,
			name: bonusProgram.name,
			termsLink: processTermsLink(bonusProgram.termsLink, bonusProgram.id),
			legalEntity: bonusProgram.legalEntity,
			bpInitialized: true,
		});
	};
	onSetName = (name) => this.setState({ name });
	onSetTermsLink = (termsLink) => this.setState({ termsLink });
	onSetLegalEntity = (legalEntity) => this.setState({ legalEntity });
	onSetTermsVersion = (termsVersion) =>
		this.setState(
			termsVersion >= 0
				? { termsVersion, termsVersionError: null }
				: { termsVersionError: 'Bitte gibt eine positive ganze Zahl an' }
		);

	hasExistingErrors = () => this.state.termsVersionError;

	save = async () => {
		if (this.hasExistingErrors()) {
			Dialog.render({
				title: 'Fehler',
				description: 'Das Formular enthält noch Fehler, bitte behebe diese vor dem Speichern.',
				buttons: [{ label: 'OK' }],
			});
			return;
		}

		this.setState({ saveLoading: true });
		try {
			const { data, error } = await this.props.client.mutate({
				mutation: gql`
					mutation UpdateBonusProgram(
						$bpId: ID!
						$name: String!
						$legalEntity: String!
						$termsVersion: Int!
						$termsLink: String
					) {
						updateBonusProgram(
							bonusProgramId: $bpId
							name: $name
							legalEntity: $legalEntity
							termsVersion: $termsVersion
							termsLink: $termsLink
						) {
							success
							bonusProgram {
								id
								name
								termsLink
								legalEntity
								termsVersion
							}
						}
					}
				`,
				variables: {
					bpId: this.state.selectedBonusProgramId,
					termsVersion: this.state.termsVersion || 1,
					termsLink: this.state.termsLink,
					name: this.state.name,
					legalEntity: this.state.legalEntity,
				},
			});

			if (!data.updateBonusProgram.success) {
				this.showErrorDialog(error);
			}
		} catch (e) {
			this.showErrorDialog(e);
		}
		this.setState({ saveLoading: false });
	};

	showErrorDialog = (error) => {
		Dialog.render({
			title: 'Fehler',
			description:
				'Beim Speichern ist ein Fehler aufgetreten. Bitte kontaktiere den Support (support@cinuru.com). \n\nFehlermeldung: ' +
				JSON.stringify(error).substr(0, 200),
			buttons: [{ label: 'OK' }],
		});
	};

	render = () => {
		return (
			<React.Fragment>
				<Wrapper>
					<BonusprogramSelectField onChange={this.handleSelectBonusprograms} />
				</Wrapper>
				<Wrapper>
					{this.state.selectedBonusProgramId && (
						<WithData
							fetchPolicy="network-only"
							query={gql`
								query BonusProgram($id: ID!) {
									bonusProgram(id: $id) {
										id
										name
										termsLink
										legalEntity
										termsVersion
									}
								}
							`}
							//do not care about performance here as each rereder should also rereder this
							//eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
							variables={{ id: this.state.selectedBonusProgramId }}
						>
							{({ bonusProgram }, _, loading) => {
								if (!this.state.bpInitialized && bonusProgram && !loading) {
									//Initialize the Bonusprogram state if a) it was not initialized before, b+c) It has finished Loading
									// if we initialize it before it has finished loading, data gets overriden when switching cinemas
									this.initializeBonusProgramState(bonusProgram);
								}
								if (!bonusProgram) {
									return <Heading size="l">Bonusprogramm konnte nicht geladen werden</Heading>;
								}
								return (
									<React.Fragment key={bonusProgram.id}>
										<Heading color="$neutral2" margin="1rem">
											Bonusprogramm bearbeiten
										</Heading>
										<TextField
											value={bonusProgram.name}
											onChange={this.onSetName}
											nMargins={TEXT_FIELD_MARGINS}
											label="Name der Bonuspunkte"
											hint="Wird zum Beispiel auf der Bonuskarte in der App angezeigt."
										/>
										<TextField
											value={processTermsLink(bonusProgram.termsLink, bonusProgram.id)}
											error={this.state.linkError}
											onChange={this.onSetTermsLink}
											nMargins={TEXT_FIELD_MARGINS}
											label="Link zu den Bedingungen des Bonusprogramms"
											hint="Bitte leer lassen wenn es den Standartbedingungen entspricht."
										/>
										<EditorField
											label="Betreiber"
											hint="Üblicherweise die Betreibergesellschaft des Kinos"
											value={bonusProgram.legalEntity}
											onChange={this.onSetLegalEntity}
											numberOfLines={2}
											characterLimit={200}
										/>
										<TextField
											value={bonusProgram.termsVersion}
											error={this.state.termsVersionError}
											onChange={this.onSetTermsVersion}
											nMargins={TEXT_FIELD_MARGINS}
											valueType="number"
											numberType="int"
											label="Versionsnummer der Bonusprogram Bedingungen"
											hint="Üblicherweise die aktuellste Version (erhöhen dieser Zahl führt dazu, dass alle Nutzer dieses Bonusprograms erneut den Bedingungen zustimmen müssen.)"
										/>
										<ButtonWrapper>
											<Button
												label="SPEICHERN"
												onPress={this.save}
												loading={this.state.saveLoading}
												gradient="accentGradient0"
												textColor="$background0"
											/>
										</ButtonWrapper>
									</React.Fragment>
								);
							}}
						</WithData>
					)}
				</Wrapper>
			</React.Fragment>
		);
	};
}

export default withApollo(EditBonusprogram);
