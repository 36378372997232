import { debounce } from 'lodash';
import React from 'react';
import { useHistory, useLocation } from 'react-router';

/**
 * This hook is used to debounce the search text in the URL.
 * always use uncontrolled input components with defaultValue, key, and autoFocus to force reset the input field
 */
export const useDebouncedSearchText = (delay = 500) => {
	const { search } = useLocation();

	const queryText = new URLSearchParams(search).get('search') || '';

	const history = useHistory();

	const setQueryText = React.useCallback(
		debounce((val) => {
			history.replace({
				search: `?search=${val}`,
			});
		}, delay),
		[]
	);

	return {
		queryText,
		setQueryText,
	};
};
