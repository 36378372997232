import React, { ReactNode } from 'react';
import { Box, Input, Button } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import styledWeb from 'styled-components';

import { uploadImageToFileServer, Image, resizeImage } from '../utils/image';
import { useSnackbar } from 'notistack';

const UploadButtonWrapper = styledWeb(Box)`
	margin: 0;
	> .UploadButton-input {
		display: none;
	};
`;

export function UploadLogoButton({
	disabled = false,
	children = 'Upload',
	onUploaded,
}: {
	onUploaded: (filePaths: [string, ...string[]]) => any;
	disabled?: boolean;
	children?: ReactNode;
}) {
	const { enqueueSnackbar } = useSnackbar();

	const handleImageUpload = async (ev: React.ChangeEvent<HTMLInputElement>) => {
		if (ev.target.validity.valid && ev.currentTarget.files) {
			const uploadedFiles = (
				await Promise.all(
					Array.from(ev.currentTarget.files || []).map(async (file) => {
						try {
							if (['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
								return await uploadImageToFileServer(await resizeImage(file, 500), false);
							} else {
								throw new Error('Nur jpeg, jpg und png Dateien sind erlaubt');
							}
						} catch (error) {
							if (error instanceof Error) {
								console.error(error);
								enqueueSnackbar(error.message, { variant: 'error' });
							}

							return null;
						}
					})
				)
			)
				.filter((x) => x !== null)
				.map((x) => x.filePath);

			if (uploadedFiles.length > 0) {
				//@ts-ignore
				onUploaded(uploadedFiles);
			}
		}
	};

	return (
		<UploadButtonWrapper>
			<Input
				className="UploadButton-input"
				id="contained-button-file"
				type="file"
				onChange={handleImageUpload}
				disabled={disabled}
			/>

			<label htmlFor="contained-button-file">
				<Button component="span" endIcon={<CloudUpload />} disabled={disabled}>
					{children}
				</Button>
			</label>
		</UploadButtonWrapper>
	);
}
