import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { Prompt } from 'react-router';

import StickyHeaderBar from './StickyHeaderBar';
import { useDivDimensions, stickyHeaderBarHeightRem } from '../utils/dimensions';
import { IconName as _ButtonIconName } from './Button';
import { IconName as _IconButtonIconName } from './Button';
import ProgressBox from '../components/ProgressBox';

export type ButtonIconName = _ButtonIconName;
export type IconButtonIconName = _IconButtonIconName;

export type StickyHeaderButton = {
	label: string;
	icon?: JSX.Element;
	disabled?: boolean;
	onClick?: () => void;
	loading?: boolean;
	loadingText?: string;
	startIconName?: ButtonIconName;
	endIconName?: ButtonIconName;
	collapsedIconName: IconButtonIconName;
};

const DEFAULT_BUTTONS = [];
const StickyHeaderWrapper = ({
	label,
	buttons = DEFAULT_BUTTONS,
	children,
	maxContentWidth = '100rem',
	showWarningOnLeave,
	warningOnLeave = 'Änderungen werden nicht automatisch gespeichert! Fortfahren?',
	disabled,
	isLoading,
}: {
	label?: string;
	buttons?: StickyHeaderButton[];
	children: ReactNode;
	maxContentWidth?: string;
	showWarningOnLeave?: boolean;
	warningOnLeave?: string;
	disabled?: boolean;
	isLoading?: boolean;
}): JSX.Element => {
	const containerRef = React.useRef(null);
	const containerDimensions = useDivDimensions(containerRef);
	return (
		<Box ref={containerRef} width="100%">
			{showWarningOnLeave ? <Prompt message={warningOnLeave} when={showWarningOnLeave} /> : null}
			<StickyHeaderBar
				label={label}
				buttons={buttons}
				containerDimensions={containerDimensions}
				height={stickyHeaderBarHeightRem}
				additionalLeftWidth={16}
				collapsedContentBreakpoint={800}
				disabled={disabled || isLoading}
			/>
			<Box
				// maximum width but not wider than maxContentWidth, horizontally centered
				m={`${stickyHeaderBarHeightRem}rem auto 0 auto`}
				p="2rem"
				maxWidth={maxContentWidth}
				flex="1"
			>
				{isLoading ? <ProgressBox /> : <>{children}</>}
			</Box>
		</Box>
	);
};

export default StickyHeaderWrapper;
