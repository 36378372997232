import React from 'react';
import { gql } from 'graphql-tag';
import { useQuery } from 'react-apollo';
import cookies from 'js-cookie';
import client from '../apollo';
import { ID } from '@cinuru/utils/types';
import { TargetGroupFilterConfig } from '../screens/CinemaOperatingCompany/queries';

export const CinemaOperatingCompanyFragment = gql`
	fragment cinemaOperatingCompanyFragment on CinemaOperatingCompany {
		id
		name
		accessRightDashboard
		accessRightFilmStatistics
		accessRightBonusProgram
		accessRightCampaigning
		cinemas {
			id
			name
		}
		associatedUsers {
			id
			fullName
			email
		}
	}
`;

export const PRIVILEGES_QUERY = gql`
	query Privileges {
		user: currentUser {
			id
			name
			email
			privileges {
				accessRightDashboard
				accessRightFilmStatistics
				accessRightBonusProgram
				accessRightCampaigns
				adminForCinemas {
					id
					name
					city
					accessRestrictedTo
				}
				adminForBonusPrograms {
					id
				}
				adminRole
				rootRole
				supportRole
				belongsToCinemaOperatingCompanies {
					...cinemaOperatingCompanyFragment
				}
			}
		}
	}
	${CinemaOperatingCompanyFragment}
`;

export type CinemaOperatingCompany = {
	id: ID;
	name: string;
	accessRightDashboard: boolean;
	accessRightFilmStatistics: boolean;
	accessRightBonusProgram: boolean;
	accessRightCampaigning: boolean;
	cinemas?: {
		id: string;
		name: string;
	}[];
	associatedUsers?: {
		id: string;
		fullName?: string;
		email: string;
	}[];
	targetGroupFilterConfig?: TargetGroupFilterConfig[];
	pricingScheme?: string;
	pricingLevel?: string;
	externalPhoneNumber?: string;
};

export type Privileges = {
	accessRightDashboard;
	accessRightFilmStatistics?: boolean;
	accessRightBonusProgram?: boolean;
	accessRightCampaigns?: boolean;
	adminForCinemas: {
		id: ID;
		name: string;
		city: string;
		accessRestrictedTo: string;
	}[];
	adminForBonusPrograms: {
		id: ID;
	};
	adminRole?: boolean;
	rootRole?: boolean;
	supportRole?: boolean;
	belongsToCinemaOperatingCompanies?: CinemaOperatingCompany[];
};

export type UserPrivilegesQueryType = {
	user: {
		id: string;
		name: string;
		email: string;
		privileges: Privileges;
	};
};

export const useUserPrivileges = () => {
	const { data, loading } = useQuery<UserPrivilegesQueryType>(PRIVILEGES_QUERY, {
		fetchPolicy: 'cache-first',
	});
	return React.useMemo(() => (loading ? undefined : data?.user?.privileges || null), [
		data?.user?.privileges,
		loading,
	]);
};

export const logout = async () => {
	await client.mutate({
		mutation: gql`
			mutation Logout {
				logout {
					success
				}
			}
		`,
	});
	client.stop();
	client.resetStore();
	cookies.remove('_csrf');
	window.location.href = '/';
};
