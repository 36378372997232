import React from 'react';
import { Box } from '@mui/material';

const Image = ({
	src,
	m = '0rem',
	borderRadius,
	width = '10rem',
	height = '10rem',
	objectFit = 'none',
}: {
	src?: string;
	m?: string;
	borderRadius?: string;
	width?: string;
	height?: string;
	objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
}): JSX.Element => {
	const imageStyle = React.useMemo(
		() => ({
			objectFit,
		}),
		[objectFit]
	);

	return (
		<Box m={m} borderRadius={borderRadius} overflow="hidden" width={width} height={height}>
			<img width="100%" height="100%" src={src} style={imageStyle} />
		</Box>
	);
};

export default Image;
