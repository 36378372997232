import { default as gql } from 'graphql-tag';
import client from '../apollo';
import { useQuery } from 'react-apollo';
import { ApolloError } from 'apollo-client';
import React from 'react';
import { ID } from 'utils/types';
import { Cinema } from './cinema';

export const BeaconFragment = gql`
	fragment BeaconFragment on Beacon {
		id
		major
		minor
		lastUpdated
		lastScan
		cinema {
			id
			name
		}
		comments
		lastBatteryChange
	}
`;

export type Beacon = {
	id: number;
	major: number;
	minor: number;
	lastUpdated: Date;
	lastScan: Date;
	cinema: Cinema;
	comments: string;
	lastBatteryChange: Date;
};

export const useBeacons = (): { beacons: Beacon[]; loading: boolean; refetch: () => void } => {
	const { data, loading, refetch } = useQuery(
		gql`
			query FetchBeacons {
				beacons {
					...BeaconFragment
				}
			}
			${BeaconFragment}
		`,
		{
			fetchPolicy: 'cache-and-network',
		}
	);
	return React.useMemo(
		() => ({
			beacons: data?.beacons || [],
			loading,
			refetch,
		}),
		[data?.beacons, loading, refetch]
	);
};

export const useBeaconById = (id?: ID): { data: Beacon; error?: ApolloError; loading: boolean } => {
	const { data, error, loading } = useQuery(
		gql`
			query BeaconById($id: ID!) {
				beaconById(id: $id) {
					...BeaconFragment
				}
			}
			${BeaconFragment}
		`,
		{
			variables: { id },
			skip: !id,
		}
	);
	return {
		data: data?.beaconById,
		error,
		loading,
	};
};

export const createBeacon = async ({
	cinemaId,
	major,
	minor,
	comments,
	lastBatteryChange,
}: {
	cinemaId: ID;
	major: number;
	minor: number;
	comments?: string;
	lastBatteryChange?: Date;
}): Promise<
	| { success: true; error: null; beacon?: Beacon }
	| { success: false; error: 'NETWORK_ERROR' | string; beacon?: Beacon }
> => {
	try {
		const { data } = await client.mutate({
			mutation: gql`
				mutation CreateBeacon(
					$cinemaId: ID!
					$major: Int!
					$minor: Int!
					$comments: String
					$lastBatteryChange: DateTime
				) {
					createBeacon(
						cinemaId: $cinemaId
						major: $major
						minor: $minor
						comments: $comments
						lastBatteryChange: $lastBatteryChange
					) {
						...BeaconFragment
					}
				}
				${BeaconFragment}
			`,
			variables: {
				cinemaId,
				major,
				minor,
				comments,
				lastBatteryChange,
			},
		});

		const result = data?.createBeacon;

		return {
			success: true,
			error: null,
			beacon: result,
		};
	} catch (e: any) {
		if (e.networkError) {
			return { success: false, error: 'NETWORK_ERROR', beacon: undefined };
		} else {
			throw e;
		}
	}
};

export const updateBeacon = async ({
	beaconId,
	cinemaId,
	major,
	minor,
	comments,
	lastBatteryChange,
	lastScan,
}: {
	beaconId: ID;
	cinemaId: ID;
	major: number;
	minor: number;
	comments?: string;
	lastBatteryChange?: Date;
	lastScan?: Date;
}): Promise<
	| { success: true; error: null; beacon?: Beacon }
	| { success: false; error: 'NETWORK_ERROR' | string; beacon?: Beacon }
> => {
	console.log(typeof lastBatteryChange);

	try {
		const { data } = await client.mutate({
			mutation: gql`
				mutation UpdateBeacon(
					$id: ID!
					$cinemaId: ID!
					$major: Int!
					$minor: Int!
					$comments: String
					$lastBatteryChange: DateTime
					$lastScan: DateTime
				) {
					updateBeacon(
						id: $id
						cinemaId: $cinemaId
						major: $major
						minor: $minor
						comments: $comments
						lastBatteryChange: $lastBatteryChange
						lastScan: $lastScan
					) {
						...BeaconFragment
					}
				}
				${BeaconFragment}
			`,
			variables: {
				id: beaconId,
				cinemaId,
				major,
				minor,
				comments,
				lastBatteryChange,
				lastScan,
			},
		});

		const result = data?.updateBeacon;

		return {
			success: true,
			error: null,
			beacon: result.updateBeacon,
		};
	} catch (e: any) {
		if (e.networkError) {
			return { success: false, error: 'NETWORK_ERROR', beacon: undefined };
		} else {
			throw e;
		}
	}
};

export const deleteBeacon = async (
	beaconId: ID
): Promise<
	{ success: true; error: null } | { success: false; error: 'NETWORK_ERROR' | string }
> => {
	try {
		const { data } = await client.mutate({
			mutation: gql`
				mutation DeleteBeacon($id: ID!) {
					deleteBeacon(id: $id) {
						success
					}
				}
			`,
			variables: { id: beaconId },
		});

		const result = data?.deleteBeacon;

		if (result.success) {
			return { success: true, error: null };
		} else {
			return { success: false, error: 'Failed to delete beacon' };
		}
	} catch (e: any) {
		if (e.networkError) {
			return { success: false, error: 'NETWORK_ERROR' };
		} else {
			throw e;
		}
	}
};
