import React from 'react';
import Table from '../../components/Table';
import SectionWrapper2 from '../../components/SectionWrapper2';
import TextField from '../../components/TextField';
import Txt from '../../components/Txt';
import { useDebouncedSearchText } from '../../utils/search';
import LoadingBox from '../../components/LoadingBox';
import StickyHeaderWrapper from '../../components/StickyHeaderWrapper';
import { updateVoucherInstance, useSearchVoucherInstanceByQR } from '../../utils/voucher';
import Dialog from '../../components/Dialog';

const columns = [
	'Gutscheintyp',
	'Eingelöst am',
	'Eigelöst durch',
	'Gültig',
	'Bemerkung',
	'Unternehmen',
	'Aktionen',
].map((label, id) => ({
	label,
	id,
}));

const VoucherDetails: React.FC = () => {
	const { queryText, setQueryText } = useDebouncedSearchText();
	const { voucher, error, loading } = useSearchVoucherInstanceByQR(queryText);

	const handleBlockVoucher = React.useCallback(
		() => async () => {
			Dialog.render({
				title: voucher?.valid ? 'Gutschein sperren' : 'Gutschein entsperren',
				description: `Möchten Sie den Gutschein wirklich ${
					voucher?.valid ? 'sperren' : 'entsperren'
				}?`,
				buttons: [
					{
						id: `cancel-button-1-${voucher?.id}`,
						label: 'Abbrechen',
					},
					{
						id: `cancel-button-2-${voucher?.id}`,
						label: 'Weiter',
						onClick: () => voucher && updateVoucherInstance(voucher.id, !voucher.valid),
					},
				],
			});
		},
		[voucher]
	);

	const rows = React.useMemo(() => {
		if (!voucher) return [];
		return [
			{
				id: voucher.id,
				rawData: voucher,
				data: [
					{
						text: voucher.voucherClass.title,
					},
					{ text: voucher.redeemedDatetime ? voucher.redeemedDatetime : '-' },
					{
						text: voucher.user
							? `${voucher.user.fullName} - (
					${voucher.user.email})`
							: '',
					},
					{
						text: voucher.valid ? 'Ja' : voucher.redeemedDatetime ? 'Eingelöst' : 'Nein',
					},
					{
						text: voucher.reason || '-',
					},
					{
						text: voucher.company?.name || '-',
					},
					!voucher.redeemedDatetime
						? {
								buttonLabel: voucher.valid ? 'Voucher sperren' : 'Voucher entsperren',
								onPress: handleBlockVoucher(),
						  }
						: {
								text: '-',
						  },
				],
			},
		];
	}, [voucher, handleBlockVoucher]);

	return (
		<StickyHeaderWrapper label="Gutschein details" maxContentWidth="130rem">
			<SectionWrapper2 p="3rem">
				<TextField
					type="text"
					label="Gutscheincode"
					autoFocus
					key={queryText}
					defaultValue={queryText}
					onChange={setQueryText}
				/>
				{loading ? (
					<LoadingBox />
				) : error ? (
					<Txt color="error">{error.message}</Txt>
				) : !rows?.length ? null : (
					<Table
						columns={columns}
						label={'Benutzer'}
						allRows={rows}
						isSelectable={false}
						isSearchable={false}
					/>
				)}
			</SectionWrapper2>
		</StickyHeaderWrapper>
	);
};

export default VoucherDetails;
