import * as React from 'react';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ID } from '@cinuru/utils/types';
import { TextFieldProps } from '@mui/material/TextField';
import SearchSelectField, { SelectFieldItem } from './SearchSelectField';
import TextField from './TextField';
import {
	useAllTargetGroupsByCinemaOperatingCompanies,
	createTargetGroup,
	createTargetGroupErrorDict,
} from '../utils/targetGroup';
import { useUserPrivileges } from '../utils/user';
import { useSnackbar } from 'notistack';

const controls: { label: string; value: string }[] = [
	{ label: 'Zielgruppe erstellen', value: 'ADD' },
];

const TargetGroupSelectField = ({
	defaultValue,
	onChange,
	variant,
	disabled,
	m,
	campaignCinemaIds,
}: {
	defaultValue?: ID[];
	onChange?: (key: 'targetGroupIds', targetGroupIds?: ID[]) => void;
	variant?: TextFieldProps['variant'];
	disabled?: boolean;
	m?: string;
	campaignCinemaIds: ID[];
}): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const privileges = useUserPrivileges();
	const cinemaOperatingCompaniesIds = React.useMemo(
		() => privileges?.belongsToCinemaOperatingCompanies?.map(({ id }) => id),
		[privileges?.belongsToCinemaOperatingCompanies]
	);
	const allTargetGroups = useAllTargetGroupsByCinemaOperatingCompanies(cinemaOperatingCompaniesIds);
	const unarchivedTargetGroups = React.useMemo(
		() => allTargetGroups?.filter(({ status }) => status !== 'ARCHIVED'),
		[allTargetGroups]
	);
	const [loading, setLoading] = React.useState(false);

	const handleControl = React.useCallback(
		async (value?: string) => {
			if (value === 'ADD') {
				setLoading(true);
				const { success, targetGroupId, error } = await createTargetGroup(campaignCinemaIds!);
				setLoading(false);
				if (success) {
					enqueueSnackbar('Zielgruppe erstellt', { variant: 'success' });
					history.push(`/marketing/targetgroups/${targetGroupId}/edit`);
				} else {
					enqueueSnackbar(
						createTargetGroupErrorDict[error] || createTargetGroupErrorDict['UNEXPECTED_ERROR'],
						{ variant: 'error' }
					);
				}
			}
		},
		[campaignCinemaIds, enqueueSnackbar, history]
	);

	const handleChange = useCallback(
		(selectFieldItems: SelectFieldItem[]) => {
			const targetGroupIds = !selectFieldItems.length
				? undefined
				: selectFieldItems?.map(({ value }) => value as ID);
			onChange?.('targetGroupIds', targetGroupIds);
		},
		[onChange]
	);

	const defaultItems = React.useMemo(
		() =>
			defaultValue && unarchivedTargetGroups
				? defaultValue?.map((id) => ({
						value: id as string,
						label: unarchivedTargetGroups.find((tg) => tg.id === id)?.name as string,
				  }))
				: undefined,
		[defaultValue, unarchivedTargetGroups]
	);

	const allItems = React.useMemo(
		() => unarchivedTargetGroups?.map(({ id, name }) => ({ label: name, value: id as string })),
		[unarchivedTargetGroups]
	);

	return !unarchivedTargetGroups || loading ? (
		<TextField label="Zielgruppe" variant={variant} m={m} isLoading />
	) : (
		<SearchSelectField
			controls={controls}
			onHandleControl={handleControl}
			label="Zielgruppe"
			onChange={handleChange}
			variant={variant}
			defaultItems={defaultItems}
			m={m}
			allItems={allItems}
			disabled={disabled}
			flex="1"
			multi
		/>
	);
};

export default TargetGroupSelectField;
