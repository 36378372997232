import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import type { ID } from '@cinuru/utils/types';

import SelectChannels from './SelectChannels';
import StickyHeaderWrapper from '../../components/StickyHeaderWrapper';
import ContentWrapper from '../../components/ContentWrapper';
import TargetGroupSelectField from '../../components/TargetGroupSelectField';
import CinemaSelectFieldNew, {
	CinemaSelectFieldNewRef,
} from '../../components/CinemaSelectFieldNew';
import type { SelectChannelsRef } from './SelectChannels';
import PushEditorSection, { PushEditorRef } from './PushEditorSection';
import EmailEditorSection, { EmailEditorSectionRef } from './EmailEditorSection';
import TextField from '../../components/TextField';
import Txt from '../../components/Txt';
import { IconName as ButtonIconName } from '../../components/Button';
import { IconName as IconButtonIconName } from '../../components/IconButton';
import CampaignTriggerSelector, {
	CampaignTriggerSelectorRef,
	Trigger,
	triggers,
} from './CampaignTriggerSelector';
import CampaignWhenSelector, { CampaignWhenSelectorRef } from './CampaignWhenSelector';
import { CampaignLink } from '../../utils/campaign';

import useTextFieldContoller from '../../utils/useTextFieldController';
import {
	saveCampaign,
	useCampaign,
	updateCampaignsStatus,
	EditedCampaign,
	Campaign,
} from '../../utils/campaign';
import { Language } from '../../utils/language';

const EditCampaignNew = (): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const { campaignId } = useParams<{ campaignId: string }>();
	const campaign = useCampaign(campaignId);

	const [editedCampaign, setEditedCampaign] = React.useState<EditedCampaign>({});
	const [loading, setLoading] = React.useState(false);

	const [selectedChannels, setSelectedChannels] = React.useState<Campaign['channels']>([]);
	const [trigger, setTrigger] = React.useState<undefined | Trigger>();

	const canSendBeforeTrigger = React.useMemo(
		() => triggers.find((trg) => trg.trigger === trigger)?.canSendBeforeTrigger,
		[trigger]
	);

	const { textInputProps: campaignNameProps } = useTextFieldContoller({
		defaultValue: campaign?.name,
		inputLabel: 'Kampagnen-Titel',
		stateKey: 'campaignName',
		finishedInitializing: campaign !== undefined,
		onChange: (newCampaignName) => handleChangeTitle(newCampaignName),
	});

	const pushEditorRef = React.useRef<PushEditorRef>(null);
	const selectChannelsRef = React.useRef<SelectChannelsRef>(null);
	const campaignWhenSelectorRef = React.useRef<CampaignWhenSelectorRef>(null);
	const cinemaSelectFieldNewRef = React.useRef<CinemaSelectFieldNewRef>(null);
	const emailEditorSectionRef = React.useRef<EmailEditorSectionRef>(null);
	const campaignTriggerSelectorRef = React.useRef<CampaignTriggerSelectorRef>(null);

	const editedCampaignWithoutEmptyValues = Object.entries(editedCampaign)
		.filter(([_, value]) => Boolean(value))
		.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
	console.log('editedCampaign', editedCampaignWithoutEmptyValues);
	const campaignWithOutEmptyValues = {};
	for (const key in campaign) {
		if (campaign[key]) campaignWithOutEmptyValues[key] = campaign[key];
	}

	const handleSave = React.useCallback(
		async (campaignLink?: CampaignLink) => {
			if (!loading) {
				// we need at least one selected cinema to save the campaign
				const cinemaSelectFieldInvalid = cinemaSelectFieldNewRef?.current?.validate() === true;
				if (cinemaSelectFieldInvalid) {
					enqueueSnackbar('Zum Speichern muss mindestens ein Kino muss ausgwählt sein', {
						variant: 'error',
					});
					return;
				}
				setLoading(true);
				const mergedData = {
					...campaign,
					...editedCampaign,
					...(campaignLink ? { link: campaignLink } : {}),
				};
				const { error } = await saveCampaign(mergedData as EditedCampaign);
				setLoading(false);
				if (error) {
					enqueueSnackbar('Fehler beim Speichern.', { variant: 'error' });
					return false;
				} else {
					setEditedCampaign({});
					enqueueSnackbar('Kampagne gespeichert.', { variant: 'success' });
					return true;
				}
			}
		},
		[loading, campaign, editedCampaign, enqueueSnackbar]
	);

	const handleUpdateCampaign = React.useCallback((key: string, value: unknown) => {
		// when the customAppView gets saved, the campaign link will be updated and we need to automatically save the campaign
		if (key === 'channels') {
			setSelectedChannels(value as Campaign['channels']);
		}
		if (key === 'trigger') {
			setTrigger(value as Trigger);
		}
		setEditedCampaign((b) => ({ ...b, [key]: value }));
	}, []);

	const handleChangeTitle = React.useCallback(
		(newTitle) => {
			handleUpdateCampaign('name', newTitle);
		},
		[handleUpdateCampaign]
	);

	const handleChangeCinema = React.useCallback(
		(items: { id: ID; name: string }[]) => {
			const cinemaIds = items.map(({ id }) => id);
			handleUpdateCampaign('cinemaIds', cinemaIds);
		},
		[handleUpdateCampaign]
	);

	const handleChangeChannels = React.useCallback(
		(ids: ID[]) => {
			handleUpdateCampaign('channels', ids);
		},
		[handleUpdateCampaign]
	);

	const campaignWasEdited = React.useMemo(() => Object.entries(editedCampaign).length > 0, [
		editedCampaign,
	]);

	const handleActivate = React.useCallback(async () => {
		const saveSuccess = await handleSave();
		if (!saveSuccess) return;
		const errors = [
			pushEditorRef,
			selectChannelsRef,
			campaignWhenSelectorRef,
			cinemaSelectFieldNewRef,
			emailEditorSectionRef,
			campaignTriggerSelectorRef,
		].map((ref) => ref.current?.validate());
		const invalid = errors.some(Boolean);
		if (invalid) {
			enqueueSnackbar('Es fehlen Einträge.', { variant: 'error' });
		} else {
			const mergedData = { ...campaign, status: 'ACTIVE' };
			setLoading(true);
			const { success } = await saveCampaign(mergedData as EditedCampaign);
			setLoading(false);
			if (success) {
				enqueueSnackbar('Kampagne aktiviert.', { variant: 'success' });
			} else {
				enqueueSnackbar('Fehler beim Aktivieren.', { variant: 'error' });
			}
		}
	}, [campaign, enqueueSnackbar, handleSave]);

	const handleEditEmail = React.useCallback(async () => {
		setLoading(true);
		const success = await handleSave();
		setLoading(false);
		if (!success) return; // error is handled by handleSave
		history.push(`/marketing/campaigns/${campaignId}/edit-email`);
	}, [campaignId, handleSave, history]);

	const handleSetCampaignStateToEditing = React.useCallback(
		async (disableFeedback?: boolean) => {
			setLoading(true);
			const { success } = await updateCampaignsStatus([campaign!.id], 'EDITING');
			setLoading(false);
			if (disableFeedback) return;
			if (success) {
				enqueueSnackbar('Kampagne deaktiviert.', { variant: 'success' });
			} else {
				enqueueSnackbar('Fehler beim Deaktivieren.', { variant: 'error' });
			}
		},
		[campaign, enqueueSnackbar]
	);

	React.useEffect(() => {
		if (campaign?.status === 'ARCHIVED') {
			handleSetCampaignStateToEditing(true);
		}
	}, [campaign?.status, handleSetCampaignStateToEditing]);

	const campaignCinemaIds = campaign?.cinemas.map(({ id }) => id);

	const disabled = campaign?.status === 'ACTIVE';

	const buttons = React.useMemo(
		() =>
			disabled
				? [
						{
							label: 'Kampagne deaktivieren',
							onClick: handleSetCampaignStateToEditing,
							startIconName: 'CancelScheduleSend' as ButtonIconName,
							loading,
							loadingText: 'Deaktivieren...',
							collapsedIconName: 'CancelScheduleSend' as IconButtonIconName,
						},
				  ]
				: [
						{
							label: 'Kampagne aktivieren',
							onClick: handleActivate,
							startIconName: 'SendRounded' as ButtonIconName,
							loading,
							loadingText: 'Aktivieren...',
							collapsedIconName: 'SendRounded' as IconButtonIconName,
						},
						{
							label: 'Kampagne speichern',
							onClick: handleSave,
							startIconName: 'SaveOutlined' as ButtonIconName,
							loading,
							loadingText: 'Speichern...',
							collapsedIconName: 'SaveOutlined' as IconButtonIconName,
						},
				  ],
		[disabled, handleActivate, handleSetCampaignStateToEditing, handleSave, loading]
	);

	React.useEffect(() => {
		if (campaign) {
			setSelectedChannels(campaign.channels);
			setTrigger(campaign.trigger);
		}
	}, [campaign]);

	return (
		<StickyHeaderWrapper
			label="Kampagne erstellen"
			buttons={buttons}
			showWarningOnLeave={campaignWasEdited}
			warningOnLeave="Die Kampagne wurde editiert aber Änderungen werden nicht automatisch gespeichert. Ohne Speichern Fortfahren?"
			isLoading={!campaign}
		>
			{!campaign ? null : (
				<>
					<ContentWrapper>
						<Txt variant="h6">Kanalübergreifende Parameter</Txt>
						<TextField
							m="2rem 0"
							fullWidth
							variant="outlined"
							{...campaignNameProps}
							disabled={loading || disabled}
						/>
						{!campaign ? null : (
							<>
								<CampaignTriggerSelector
									onChange={handleUpdateCampaign}
									defaultValue={campaign.trigger}
									disabled={loading || disabled}
									defaultThresholdValue={campaign.triggerThreshold}
									ref={campaignTriggerSelectorRef}
								/>
								{trigger ? (
									<CampaignWhenSelector
										trigger={trigger}
										triggerLabel={triggers.find((t) => t.trigger === trigger)?.label}
										onChange={handleUpdateCampaign}
										defaultDate={campaign.sendingDate}
										defaultTime={campaign.sendingTime}
										defaultStartDate={campaign.startDate}
										defaultStartTime={campaign.startTime}
										defaultEndDate={campaign.endDate}
										defaultEndTime={campaign.endTime}
										defaultNumberOfIntervals={campaign.numberOfIntervals}
										defaultIntervalEndType={campaign.intervalEndType}
										defaultEventType={campaign.eventType}
										defaultTimeUnit={campaign.timeUnit}
										defaultTimeUnitFactor={campaign.timeUnitFactor}
										ref={campaignWhenSelectorRef}
										disabled={loading || disabled}
										canSendBeforeTrigger={canSendBeforeTrigger}
									/>
								) : null}
							</>
						)}
						<CinemaSelectFieldNew
							label="Center auswählen"
							onChange={handleChangeCinema}
							defaultIds={campaign?.cinemas?.map(({ id }) => id)}
							multi
							ref={cinemaSelectFieldNewRef}
							disabled={loading || disabled}
							m="2rem 0 0 0"
						/>
						<TargetGroupSelectField
							variant="outlined"
							defaultValue={campaign.targetGroupIds}
							onChange={handleUpdateCampaign}
							disabled={loading || disabled}
							m="2rem 0 1rem"
							campaignCinemaIds={campaignCinemaIds!}
						/>
						<SelectChannels
							label="Kanäle"
							selectedChannels={selectedChannels}
							onChange={handleChangeChannels}
							ref={selectChannelsRef}
							disabled={loading || disabled}
						/>
					</ContentWrapper>

					{selectedChannels.includes('PUSH') ? (
						<PushEditorSection
							onChange={handleUpdateCampaign}
							defaultMessage={campaign.message}
							defaultMessageTitle={campaign.messageTitle}
							defaultMessageImage={campaign.messageImage}
							defaultLink={campaign.link as CampaignLink}
							ref={pushEditorRef}
							bonusProgramIds={campaign.bonusProgramIds}
							disabled={loading || disabled}
							language={campaign.language.id as Language}
							campaignCinemaIds={campaignCinemaIds!}
							onSaveCampaign={handleSave}
						/>
					) : null}
					{selectedChannels.includes('EMAIL') ? (
						<EmailEditorSection
							defaultEmailSubject={campaign.emailSubject}
							onChange={handleUpdateCampaign}
							campaign={campaign}
							ref={emailEditorSectionRef}
							onHandleEditEmail={handleEditEmail}
							loading={loading}
							disabled={loading || disabled}
						/>
					) : null}
				</>
			)}
		</StickyHeaderWrapper>
	);
};

export default EditCampaignNew;
