import React, { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';

import { ID } from '@cinuru/utils/types';

import Table from '../../components/Table';
import TextField from '../../components/TextField';
import SectionWrapper2 from '../../components/SectionWrapper2';
import Txt from '../../components/Txt';
import Button from '../../components/Button';
import { updateUser, updateUserSubscription, User, useUserById } from '../../utils/users';
import Dialog from '../../components/Dialog';
import StickyHeaderWrapper from '../../components/StickyHeaderWrapper';
import { getEuroTextAmount, useLabelAndValue } from '../../utils/helpers';
import { formatDateString } from '../../utils/time';
import { format } from 'date-fns';

import SearchSelectField2 from '../../components/SearchSelectField2';
import lodash from 'lodash';
import { PRIVILEGES_QUERY, UserPrivilegesQueryType, useUserPrivileges } from '../../utils/user';
import { cancelTicket, syncTicket, Ticket, updateTicket } from '../../utils/ticket';
import client from '../../apollo';
import { mapColumns } from '../../utils';

const invoicesColumns = mapColumns(['Datum', 'Betrag', 'Download']);

const subscriptionsColumns = mapColumns([
	'Abo',
	'Gültig von',
	'Bezahlt bis',
	'Aktiv',
	'Gesperrt',
	'Gekündigt',
	'Gesperrt wegen',
	'Unternehmen',
	'Details zur Sperrung',
	'Paypal-Bestellnummer',
	'Kündigung wirksam ab',
	'Aktionen',
]);

const blockedReasonDict = {
	OTHER: 'Durch Support gesperrt',
	NO_SHOW: 'No Show',
	PAYMENT_FAILED: 'Zahlung fehlgeschlagen',
};

const ticketStatusDict = {
	COMPLETE: 'Gültig',
	RESERVATION: 'Reserviert',
	REFUNDED: 'Erstattet',
	REFUND_FAILED: 'Erstattung fehlgeschlagen',
	REFUND_WAITING: 'Erstattung wartet',
	PREPARATION: 'Concessions werden zubereitet',
};

const ticketsColumns = mapColumns([
	'Film',
	'Kino',
	'Vorstellung',
	'Plätze',
	'Gekauft am',
	'Ticketart',
	'Status',
	'Einlasscode',
	'Gescannt',
	'Aktionen',
]);

const vouchersColumns = mapColumns(['Gutscheincode', 'Gutscheintyp', 'Dauer', 'Zeit der Erlösung']);

const linkedAccountsColumns = mapColumns(['Name', 'E-mail', 'Datum']);

const BlockDialogContent = ({
	dismissPortal,
	errorMessage,
	successMessage,
	onSubmit,
	title,
	shouldBlock,
}: {
	dismissPortal: () => void;
	errorMessage: string;
	successMessage: string;
	onSubmit: (blockedText: string) => Promise<{ success?: boolean; error?: string }>;
	title: string;
	shouldBlock: boolean;
}) => {
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = React.useState(false);
	const [blockedText, setBlockedText] = React.useState('');
	const handleBlockedTextChange = React.useCallback((value) => {
		setBlockedText(value);
	}, []);
	const handleSubmit = React.useCallback(async () => {
		setLoading(true);
		const { success, error } = await onSubmit(blockedText);
		setLoading(false);
		if (success) {
			enqueueSnackbar(successMessage, { variant: 'success' });
			dismissPortal();
		} else {
			enqueueSnackbar(`${errorMessage}: ${error ? error : 'Unbekannter Fehler'}`, {
				variant: 'error',
			});
		}
		dismissPortal();
	}, [onSubmit, blockedText, dismissPortal, enqueueSnackbar, successMessage, errorMessage]);
	return (
		<Box p="2rem" width="50rem">
			<Txt variant="h6">{title}</Txt>
			{shouldBlock ? (
				<Box display="flex" flexDirection="column">
					<Txt m="2rem 0">Bitte gib einen Grund an, wenn der Nutzer gesperrt werden soll.</Txt>
					<TextField
						m="1rem 0 1rem 0"
						value={blockedText}
						onChange={handleBlockedTextChange}
						placeholder="Grund"
						label="Grund"
					/>
				</Box>
			) : (
				<Txt m="2rem 0">Möchten Sie den Nutzer entsperren?</Txt>
			)}
			<Box display="flex" gap="1rem" justifyContent="flex-end" alignItems="center" m="3rem 0 0 0">
				<Button label="Abbrechen" variant="outlined" onClick={dismissPortal} />
				<Button
					label={shouldBlock ? 'Sperren' : 'Entsperren'}
					onClick={handleSubmit}
					loading={loading}
					color={shouldBlock ? 'error' : 'primary'}
					disabled={loading || (shouldBlock && !blockedText)}
					variant="outlined"
				/>
			</Box>
		</Box>
	);
};

const EidtUserRender = ({ user }: { user: User }) => {
	const { enqueueSnackbar } = useSnackbar();
	const viewerPrivileges = useUserPrivileges();
	const availableCinemaOperatingCompanies = viewerPrivileges?.belongsToCinemaOperatingCompanies;

	const selfId = client.readQuery<UserPrivilegesQueryType>({ query: PRIVILEGES_QUERY })!.user.id;
	const viewerCanDefineCinemaAdmins = viewerPrivileges?.adminRole && user.id !== selfId;
	const ticketIdRef = useRef<ID | null>(null);

	const [formState, setFormState] = React.useState({
		firstName: user.firstName ?? '',
		lastName: user.lastName ?? '',
		street: user.street ?? '',
		houseNumber: user.houseNumber ?? '',
		zipCode: user.zipCode ?? '',
		city: user.city ?? '',
		email: user.email ?? '',
	});

	const [nameChangeLoading, setNameChangeLoading] = React.useState(false);
	const [isNameChangeEnabled, setIsNameChangeEnabled] = React.useState(false);

	const handleInputChange = React.useCallback(
		(name: string) => (value: string) => {
			setFormState((prevState) => ({
				...prevState,
				[name]: value,
			}));
		},
		[]
	);

	const handleBlockUser = React.useCallback(
		(blocked: boolean) => async (blockedText: string) => {
			return updateUser({ userId: user.id, blocked, blockedText });
		},
		[user.id]
	);
	const handleResetAppChangeBlockedUntil = React.useCallback(async () => {
		try {
			const { success, error } = await updateUser({
				userId: user.id,
				resetAppChangeBlockedUntil: true,
			});
			if (!success) {
				enqueueSnackbar(
					`Fehler beim Zurücksetzen der App Sperre: ${error ? error : 'Unbekannter Fehler'}`,
					{ variant: 'error' }
				);
				return;
			}
			enqueueSnackbar('App Sperre zurückgesetzt', { variant: 'success' });
			// eslint-disable-next-line no-catch-all/no-catch-all
		} catch (e) {
			enqueueSnackbar('Fehler beim Zurücksetzen der App Sperre', { variant: 'error' });
		}
	}, [enqueueSnackbar, user.id]);

	const handleToggleBlockUserSubscription = React.useCallback(
		(subscriptionId: ID, block: boolean) => async (blockedText: string) => {
			return updateUserSubscription(subscriptionId, block, blockedText);
		},
		[]
	);

	const renderBlockedDialogContent = React.useCallback(
		(subscriptionId?: ID, isSubscriptionBlocked?: boolean) => ({ dismissPortal }) => {
			return (
				<BlockDialogContent
					dismissPortal={dismissPortal}
					errorMessage="Fehler beim Sperren"
					successMessage="Erfolgreich gesperrt"
					onSubmit={
						subscriptionId
							? handleToggleBlockUserSubscription(subscriptionId, !isSubscriptionBlocked)
							: handleBlockUser(!user.blocked)
					}
					shouldBlock={subscriptionId ? !isSubscriptionBlocked : !user.blocked}
					title={
						subscriptionId
							? isSubscriptionBlocked
								? 'Abo entsperren'
								: 'Abo sperren'
							: user.blocked
							? 'Benutzer entsperren'
							: 'Benutzer sperren'
					}
				/>
			);
		},
		[handleBlockUser, handleToggleBlockUserSubscription, user.blocked]
	);

	const handleBlock = React.useCallback(() => {
		Dialog.render({
			renderContent: renderBlockedDialogContent(),
		});
	}, [renderBlockedDialogContent]);

	const handleBlockSubscriptionButtonClicked = React.useCallback(
		(subscriptionId: ID, isSubscriptionBlocked: boolean) => () => {
			Dialog.render({
				renderContent: renderBlockedDialogContent(subscriptionId, isSubscriptionBlocked),
			});
		},
		[renderBlockedDialogContent]
	);

	const handleMarkTicketAsScannedClicked = React.useCallback((ticketId) => {
		Dialog.render({
			title: 'Ticket als gescannt markieren?',
			description: 'Möchten Sie das Ticket als gescannt markieren?',
			buttons: [
				{
					label: 'Ja',
					id: '2',
					variant: 'outlined',
					onClick: () =>
						updateTicket({
							id: ticketId,
							scanned: true,
						}),
				},
				{
					label: 'Abbrechen',
					id: '1',
					variant: 'outlined',
				},
			],
		});
	}, []);

	const handleNameChangeButtonClicked = React.useCallback(async () => {
		if (isNameChangeEnabled) {
			setNameChangeLoading(true);
			const { success, error, user: updatedUser } = await updateUser({
				userId: user.id,
				...formState,
			});
			if (success) {
				enqueueSnackbar('Benutzer erfolgreich aktualisiert', { variant: 'success' });
			} else {
				enqueueSnackbar(
					`Fehler beim Aktualisieren des Benutzers: ${error ? error : 'Unbekannte Fehler'}`,
					{
						variant: 'error',
					}
				);
				if (updatedUser) {
					setFormState({
						firstName: updatedUser.firstName ?? '',
						lastName: updatedUser.lastName ?? '',
						street: updatedUser.street ?? '',
						houseNumber: updatedUser.houseNumber ?? '',
						zipCode: updatedUser.zipCode ?? '',
						city: updatedUser.city ?? '',
						email: updatedUser.email ?? '',
					});
				}
			}
			setNameChangeLoading(false);
		}
		setIsNameChangeEnabled((prev) => !prev);
	}, [isNameChangeEnabled, user.id, formState, enqueueSnackbar]);

	const performTicketCancellation = React.useCallback(async (id) => {
		const { success } = await cancelTicket(id);
		if (!success) {
			Dialog.render({
				title: 'Fehler',
				description:
					'Beim Stornieren der Buchung ist ein unerwarteter Fehler aufgetreten. Bitte versuche es erneut.',
			});
		}
	}, []);

	const cancelTicketViaBrowser = React.useCallback((url: string, ticketId: ID) => {
		ticketIdRef.current = ticketId;
		window.open(url, '_blank', 'noopener,noreferrer');
	}, []);

	// Trigger ticket sync after it is cancelled viua browser
	useEffect(() => {
		const handleFocus = async () => {
			if (ticketIdRef.current) {
				await syncTicket(ticketIdRef.current);
				ticketIdRef.current = null;
			}
		};

		window.addEventListener('focus', handleFocus);

		return () => {
			window.removeEventListener('focus', handleFocus);
		};
	}, []);

	const handleCancelTicket = React.useCallback(
		async (ticket: Ticket) => {
			if (ticket && new Date(ticket.screening.datetime) > new Date()) {
				Dialog.render({
					title: 'Stornieren',
					description: 'Willst du zum Online-Ticketing gehen, um die Buchung zu stornieren?',
					buttons: [
						{
							id: `cancel-button-1-${ticket.id}`,
							label: 'Abbrechen',
						},
						{
							id: `cancel-button-2-${ticket.id}`,
							label: 'Weiter',
							onClick: ticket.cancelationLink
								? () => cancelTicketViaBrowser(ticket.cancelationLink, ticket.id)
								: () => performTicketCancellation(ticket.id),
						},
					],
				});
			}
		},
		[performTicketCancellation, cancelTicketViaBrowser]
	);

	const invoicesRows = React.useMemo(
		() =>
			(user.invoices ?? [])
				.sort((a, b) => {
					if (a.date > b.date) return -1;
					if (a.date < b.date) return 1;
					return 0;
				})
				.map((invoice) => ({
					id: invoice.id,
					rawData: invoice,
					data: [
						{
							text: invoice.date,
						},
						{
							text: getEuroTextAmount(invoice.priceInCents),
						},
						{
							buttonLabel: 'Rechnung öffnen',
							onPress: () => window.open(invoice.url, '_blank'),
						},
					],
				})),
		[user.invoices]
	);

	const relevantSubscriptions = user.subscriptions?.filter(
		(s) => s.paypalStatus === 'ACTIVE' || s.paypalStatus === 'CANCELLED'
	);

	const subscriptionsRows = React.useMemo(
		() =>
			(relevantSubscriptions || []).map((subscription) => ({
				id: subscription.id,
				rawData: subscription,
				data: [
					{
						text: subscription.subscriptionTier.name,
					},
					{
						text: formatDateString(subscription.validFrom),
					},
					{
						text: formatDateString(subscription.payedUntil),
					},
					{
						text: subscription.active ? 'Ja' : 'Nein',
					},
					{
						text: subscription.blocked ? 'Ja' : 'Nein',
					},
					{
						text: subscription.canceled ? 'Ja' : 'Nein',
					},
					{
						text: blockedReasonDict[subscription.blockedReason],
					},
					{ text: subscription.company?.name ?? '-' },
					{
						text: subscription.blockedText,
					},
					{
						text: subscription.paypalOrderId,
					},
					{
						text: formatDateString(subscription.cancellationEffectiveDate),
					},
					{
						buttonLabel: subscription.blocked ? 'Abo entsperren' : 'Abo sperren',
						onPress: handleBlockSubscriptionButtonClicked(subscription.id, subscription.blocked),
					},
				],
			})),
		[handleBlockSubscriptionButtonClicked, relevantSubscriptions]
	);

	const ticketsRows = React.useMemo(
		() =>
			(user.tickets ?? []).map((ticket) => ({
				id: ticket.id,
				rawData: ticket,
				data: [
					{
						text: ticket.screening.movie.title,
					},
					{ text: ticket.screening.cinema.name },
					{
						text: `${format(new Date(ticket.screening.datetime), 'dd.MM.yyyy HH:mm')}${
							ticket.auditoriumName ? `  (${ticket.auditoriumName})` : ''
						}`,
					},
					{ text: ticket.seats.map((seat) => `${seat.rowName} ${seat.seatName}`).join(', ') },
					{
						text: new Date(ticket.boughtAt).toLocaleDateString('de-DE'),
					},
					{
						text: ticket.isFlatrateTicket ? 'Flatrate Ticket' : 'Standard Ticket',
					},

					{
						text: ticketStatusDict[ticket.status],
					},
					{
						text: ticket.qrCode,
					},
					...(ticket.status === 'REFUNDED'
						? [{ text: '-' }]
						: ticket.scanned
						? [{ text: 'Ja' }]
						: [
								{
									buttonLabel: 'Gescannt setzen',
									onPress: () => handleMarkTicketAsScannedClicked(ticket.id),
								},
						  ]),
					...(ticket.refundable && new Date(ticket.screening.datetime) > new Date()
						? [
								{
									buttonLabel: 'Ticket stornieren',
									onPress: () => {
										handleCancelTicket(ticket);
									},
								},
						  ]
						: [
								{
									text: '-',
								},
						  ]),
				],
			})),
		[handleMarkTicketAsScannedClicked, user.tickets, handleCancelTicket]
	);

	const vouchersRows = React.useMemo(
		() =>
			(user.vouchers ?? []).map((voucher) => ({
				id: voucher.id,
				rawData: voucher,
				data: [
					{
						text: voucher.qrCode ?? '-',
					},
					{ text: voucher.voucherClass.title ?? '-' },
					{
						text:
							voucher.redeemedDatetime && voucher.createdDatetime
								? `${Math.floor(
										(new Date(voucher.redeemedDatetime).getTime() -
											new Date(voucher.createdDatetime).getTime()) /
											(1000 * 60 * 60 * 24)
								  )} days` // Converts duration to days
								: '-',
					},
					{
						text: voucher.redeemedDatetime
							? new Date(voucher.redeemedDatetime).toLocaleString()
							: '-',
					},
				],
			})),
		[user.vouchers]
	);

	const linkedAccountsRows = useMemo(
		() =>
			user.linkedAccounts
				?.filter((l) => !!l.acceptedDatetime)
				.map((linkAcc) => ({
					id: linkAcc.userId,
					rawData: linkAcc,
					data: [
						{ text: linkAcc.username },
						{ text: linkAcc.email },
						{ text: new Date(linkAcc.acceptedDatetime!).toLocaleString() },
					],
				})),
		[user.linkedAccounts]
	);

	// change userAdmin cinemas logic
	const searchedUserDefaultAdminCinemas = useLabelAndValue({
		items: user.privileges?.belongsToCinemaOperatingCompanies,
		labelKey: 'name',
		valueKey: 'id',
	});

	const searchedUserDefaultAdminCinemaValues = React.useMemo(
		() => searchedUserDefaultAdminCinemas?.map((cinema) => cinema.value),
		[searchedUserDefaultAdminCinemas]
	);

	const [selectedAdminCinemas, setSelectedAdminCinemas] = React.useState<
		{ label: string; value: string }[]
	>(searchedUserDefaultAdminCinemas || []);

	const selectedAdminCinemasChanged = React.useMemo(
		() =>
			!lodash.isEqual(
				selectedAdminCinemas.map((cinema) => cinema.value),
				user.privileges?.belongsToCinemaOperatingCompanies?.map((cinema) => cinema.id)
			),
		[selectedAdminCinemas, user.privileges?.belongsToCinemaOperatingCompanies]
	);

	const handleSaveAdminCinemas = React.useCallback(async () => {
		const { success, error } = await updateUser({
			userId: user.id,
			adminCinemaOperatingCompanyIds: selectedAdminCinemas.map((cinema) => cinema.value),
		});
		if (success) {
			enqueueSnackbar('Admin Rechte erfolgreich gespeichert', { variant: 'success' });
		} else {
			enqueueSnackbar(
				`Fehler beim Speichern der Admin Rechte: ${error ? error : 'Unbekannter Fehler'}`,
				{
					variant: 'error',
				}
			);
		}
	}, [selectedAdminCinemas, user.id]);

	const handleShowSaveAdminCinemasDialog = React.useCallback(() => {
		Dialog.render({
			title: 'Admin Rechte speichern?',
			description: !selectedAdminCinemas.length
				? 'Soll der Nutzer alle Admin Rechte entzogen bekommen?'
				: `Soll der Nutzer die Admin Rechte für die ausgewählten Kinos erhalten?`,
			buttons: [
				{
					label: 'Abbrechen',
					id: '1',
					variant: 'outlined',
				},
				{
					label: 'Speichern',
					id: '2',
					variant: 'outlined',
					onClick: handleSaveAdminCinemas,
				},
			],
		});
	}, [handleSaveAdminCinemas, selectedAdminCinemas.length]);

	return (
		<Box width="100%">
			<SectionWrapper2 label="Name" p="2rem">
				<Box flexDirection="row" display="flex" gap="2rem" width="100%">
					<TextField
						name="firstName"
						value={formState.firstName}
						onChange={handleInputChange('firstName')}
						label="Vorname"
						placeholder="Vorname"
						disabled={!isNameChangeEnabled}
						fullWidth
						width="100%"
					/>
					<TextField
						name="lastName"
						value={formState.lastName}
						onChange={handleInputChange('lastName')}
						placeholder="Nachname"
						label="Nachname"
						disabled={!isNameChangeEnabled}
						fullWidth
						width="100%"
					/>
				</Box>

				<Button
					m="2rem 0 0 0"
					onClick={handleNameChangeButtonClicked}
					label={isNameChangeEnabled ? 'Speichern' : 'Namen ändern'}
					loading={nameChangeLoading}
					variant="outlined"
				/>
			</SectionWrapper2>
			<SectionWrapper2
				label={user.blocked ? 'Nutzer entsperren' : 'Nutzer sperren'}
				p="2rem"
				m="2rem 0 0 0"
			>
				<Box flexDirection="row" display="flex" gap="2rem" width="100%">
					<Button
						onClick={handleBlock}
						color={!user.blocked ? 'error' : 'primary'}
						label={!user.blocked ? 'Benutzer sperren' : 'Benutzer entsperren'}
						variant="outlined"
					/>
					<Button
						onClick={handleResetAppChangeBlockedUntil}
						color={'primary'}
						label={'App Sperre zurücksetzen'}
						variant="outlined"
					/>
				</Box>
			</SectionWrapper2>
			<SectionWrapper2 label="Admin Rechte" p="2rem" m="2rem 0 0 0">
				<SearchSelectField2
					label="FTBs auswählen"
					options={availableCinemaOperatingCompanies}
					labelKey="name"
					valueKey="id"
					m="1rem 0 0 0"
					onChange={setSelectedAdminCinemas}
					defaultValues={searchedUserDefaultAdminCinemaValues}
					multiple
					disabled={!viewerCanDefineCinemaAdmins}
				/>
				<Button
					m="2rem 0 0 0"
					onClick={handleShowSaveAdminCinemasDialog}
					label="Speichern"
					loading={nameChangeLoading}
					variant="outlined"
					color="error"
					disabled={!selectedAdminCinemasChanged || !viewerCanDefineCinemaAdmins}
				/>
			</SectionWrapper2>
			<SectionWrapper2 label="Rechnungen" p="2rem" m="2rem 0 0 0">
				<Table
					columns={invoicesColumns}
					label={''}
					defaultRowsPerPage={5}
					allRows={invoicesRows}
					isSelectable={false}
					isSearchable={false}
				/>
			</SectionWrapper2>

			<SectionWrapper2 label="Abos" p="2rem" m="2rem 0 0 0">
				<Table
					label={''}
					defaultRowsPerPage={5}
					columns={subscriptionsColumns}
					allRows={subscriptionsRows}
					isSelectable={false}
					isSearchable={false}
				/>
			</SectionWrapper2>

			<SectionWrapper2 label="Tickets" p="2rem" m="2rem 0 0 0">
				<Table
					label={''}
					columns={ticketsColumns}
					defaultRowsPerPage={5}
					allRows={ticketsRows}
					isSelectable={false}
					isSearchable={false}
				/>
			</SectionWrapper2>

			<SectionWrapper2 label="Vouchers" p="2rem" m="2rem 0 0 0">
				<Table
					label={''}
					columns={vouchersColumns}
					defaultRowsPerPage={5}
					allRows={vouchersRows}
					isSelectable={false}
					isSearchable={false}
				/>
			</SectionWrapper2>
			<SectionWrapper2 label="Verknüpfte Accounts" p="2rem" m="2rem 0 0 0">
				<Table
					columns={linkedAccountsColumns}
					defaultRowsPerPage={10}
					allRows={linkedAccountsRows}
					isSelectable={false}
					isSearchable={false}
				/>
			</SectionWrapper2>
		</Box>
	);
};

const EditUser: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const { data, error, loading } = useUserById(id);
	return (
		<StickyHeaderWrapper
			label={`Benutzer bearbeiten ${data?.email ? '- ' + data.email : ''}`}
			maxContentWidth="130rem"
			isLoading={loading}
		>
			{error ? <Txt color="error">{error.message}</Txt> : <EidtUserRender user={data} />}
		</StickyHeaderWrapper>
	);
};
export default EditUser;
