import React from 'react';
import styled from 'styled-components';
import shortid from 'shortid';
import { Box } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';

import Txt from './Txt';
import Button from './Button';
import { DIALOG_BACKGROUND_ZINDEX, DIALOG_ZINDEX } from '../utils/constants';

import PortalProvider from './PortalProvider';

type DialogProps = {
	title?: string;
	description?: string;
	onClose?: () => void;
	renderContent?: (val: { dismissPortal: () => void }) => JSX.Element;
	dismissPortal: () => void;
	buttons?: { id: string; label: string; onClick?: () => void; variant?: ButtonProps['variant'] }[];
	width?: string;
	backgroundColor?: string;
	positionedAtTop?: boolean;
	wrapperStyle?: { [key: string]: string | number };
	isLocked?: boolean;
};

const Background = styled(Box)`
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: grey;
	opacity: 50%;
	z-index: ${DIALOG_BACKGROUND_ZINDEX};
`;

const ContentWrapper = styled(Box)`
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 3rem;
	position: fixed;
	z-index: ${DIALOG_ZINDEX};
	overflow: scroll;
`;

const Dialog = ({
	renderContent,
	title,
	description,
	onClose,
	dismissPortal,
	buttons,
	isLocked,
}: DialogProps): JSX.Element => {
	const handleClose = React.useCallback(() => {
		onClose?.();
		dismissPortal();
	}, [dismissPortal, onClose]);

	const handleClick = React.useCallback(
		(id: string) => {
			buttons?.find((button) => button.id === id)?.onClick?.();
			dismissPortal();
		},
		[buttons, dismissPortal]
	);

	const handleStopPropagation = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
	}, []);

	return (
		<Box>
			<ContentWrapper onClick={isLocked ? undefined : handleClose} id="dialog">
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					width="100%"
					minHeight="100%"
					flex="1"
				>
					<Box
						display="flex"
						bgcolor="background.paper"
						borderRadius="1rem"
						padding={renderContent ? undefined : '3rem'}
						boxShadow="24"
						onClick={handleStopPropagation}
						flexDirection="column"
					>
						{renderContent ? (
							renderContent({ dismissPortal: handleClose })
						) : (
							<>
								<Txt m="0 0 1rem 0" variant="h6" textAlign={'center'}>
									{title}
								</Txt>
								<Txt whiteSpace={'pre-line'} textAlign={'center'}>
									{description}
								</Txt>
							</>
						)}

						{buttons && buttons.length ? (
							<Box
								width="100%"
								m="2rem 0 0"
								display="flex"
								flexDirection="row"
								justifyContent="flex-end"
								alignItems="center"
							>
								{buttons ? (
									buttons.map(({ id, label, onClick, variant }) => (
										<Button
											m="0 0 0 1rem"
											key={id}
											id={id}
											onClick={onClick ? handleClick : handleClose}
											variant={variant}
										>
											{label}
										</Button>
									))
								) : (
									<Button onClick={handleClose}>Schließen</Button>
								)}
							</Box>
						) : null}
					</Box>
				</Box>
			</ContentWrapper>
			<Background />
		</Box>
	);
};

const dialogStack: string[] = [];
Dialog.render = (props: Omit<DialogProps, 'dismissPortal'>, id?: string): void => {
	if (!id) {
		id = shortid.generate();
		dialogStack.push(id);
	}
	PortalProvider.render(id, Dialog, props);
};
Dialog.unmount = (id?: string) => {
	if (!id) id = dialogStack.pop();
	if (id) PortalProvider.unmount(id);
};

export default Dialog;
