import StickyHeaderWrapper, { StickyHeaderButton } from '../../components/StickyHeaderWrapper';
import * as React from 'react';
import Table, { RowItem } from '../../components/Table';
import { useMutation, useQuery } from 'react-apollo';
import Txt from '../../components/Txt';
import { useHistory } from 'react-router-dom';
import { AllCompaniesQuery, Company, DeleteCompaniesMutation } from '../../utils/company';
import Dialog from '../../components/Dialog';
import { useSnackbar } from 'notistack';

const columns = ['Logo', 'Name'].map((label, id) => ({
	label,
	id,
}));

const actionTypes = ['DELETE'];

export function CompanyOverview() {
	const { data, loading, error, refetch } = useQuery<{ companies: Company[] }>(AllCompaniesQuery, {
		fetchPolicy: 'cache-first',
	});

	const history = useHistory();

	const { enqueueSnackbar } = useSnackbar();

	const [deleteCompanies] = useMutation(DeleteCompaniesMutation, {
		onCompleted() {
			enqueueSnackbar('Unternehmen gelöscht', { variant: 'success' });
			refetch();
		},
	});

	const buttons = React.useMemo(
		() => [
			{
				label: 'Unternehmen erstellen',
				onClick: () => history.push('/admin/company/create'),
				startIconName: 'Add',
				loading,
				collapsedIconName: 'Add',
			} as StickyHeaderButton,
		],
		[loading]
	);

	const rows = React.useMemo<RowItem[]>(() => {
		return (
			data?.companies.map((c) => ({
				id: c.id,
				rawData: c,
				data: [
					{
						text: <img src={c.logo} width={50} height={50} alt={`${c.name} logo`} />,
					},
					{
						text: c.name,
					},
					{
						buttonLabel: 'Bearbeiten',
						onPress: () => history.push(`/admin/company/edit/${c.id}`),
					},
				],
			})) || []
		);
	}, [data]);

	const allActions = React.useMemo(() => [{ label: 'Löschen', type: 'DELETE' }], []);

	return (
		<StickyHeaderWrapper
			maxContentWidth="130rem"
			label={'Unternehmen bearbeiten'}
			isLoading={loading}
			buttons={buttons}
		>
			{!loading && (
				<>
					{error && <Txt color="error">{error.message}</Txt>}
					<Table
						columns={columns}
						allRows={rows}
						actions={{
							allActions,
							getPossibleActionTypes: () => actionTypes,
						}}
						isSelectable={true}
						isSearchable={true}
						onHandleAction={async (actionType, selectedRowIds) => {
							if (actionType === 'DELETE') {
								const companyNames = selectedRowIds.map(
									(id) => data?.companies.find((c) => c.id === id)!.name
								);
								Dialog.render({
									title: 'Wirklich löschen',
									description: `Willst du diese Unternehmen wirklich löschen?\n\n${companyNames.join(
										'\n'
									)}`,
									buttons: [
										{
											id: '1',
											label: 'Ja',
											onClick: () => deleteCompanies({ variables: { ids: selectedRowIds } }),
										},
										{ id: '2', label: 'Abbrechen' },
									],
								});
							}
						}}
					/>
				</>
			)}
		</StickyHeaderWrapper>
	);
}
