import React, { useState } from 'react';
import StickyHeaderWrapper, { StickyHeaderButton } from '../../components/StickyHeaderWrapper';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-apollo';
import {
	AllCompaniesQuery,
	CreateCompanyMutation,
	EditCompanyMutation,
	SingleCompanyQuery,
} from '../../utils/company';
import { useSnackbar } from 'notistack';
import ContentWrapper from '../../components/ContentWrapper';
import Txt from '../../components/Txt';
import TextField from '../../components/TextField';
import { UploadLogoButton } from '../../components/UploadLogoButton';
import styled from 'styled-components';
import { CloseOutlined } from '@mui/icons-material';
import { getImageSource } from '../../utils/image';

const ImgWrap = styled.div!({
	position: 'relative',
	width: 'fit-content',
});

const StyledCloseOutlined = styled(CloseOutlined)!(({ theme }) => ({
	position: 'absolute',
	top: '0.5rem',
	right: '0.5rem',
	cursor: 'pointer',
	color: 'white',
}));

export function EditCompany() {
	const { id } = useParams<{ id?: string }>();

	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	const [logo, setLogo] = useState<string | null>(null);
	const [name, setName] = useState('');

	const isEditing = !!id && !Array.isArray(id);

	const { loading } = useQuery(SingleCompanyQuery, {
		variables: {
			id,
		},
		skip: !isEditing,
		onCompleted(data) {
			if (data) {
				setLogo(data.company.logo);
				setName(data.company.name);
			}
		},
		onError() {
			enqueueSnackbar('Unternehmen nicht gefunden', { variant: 'error' });
			history.push('/admin/companies');
		},
	});

	const onCreateOrEdit = () => {
		enqueueSnackbar(isEditing ? 'Unternehmen bearbeiten' : 'Unternehmen erstellt', {
			variant: 'success',
		});
		history.push('/admin/companies');
	};

	const [createCompany, { loading: loadingCreate, called: calledSave }] = useMutation(
		CreateCompanyMutation,
		{
			onCompleted: onCreateOrEdit,
			refetchQueries: [{ query: AllCompaniesQuery }],
		}
	);

	const [editCompany, { loading: loadingUpdate, called: calledUpdate }] = useMutation(
		EditCompanyMutation,
		{
			onCompleted: onCreateOrEdit,
		}
	);

	const canSave = !!logo && !!name?.trim();

	const handleSave = () => {
		if (!isEditing) {
			createCompany({
				variables: {
					name,
					logo,
				},
			});
		} else {
			editCompany({
				variables: {
					id,
					name,
					logo,
				},
			});
		}
	};

	const buttons = [
		{
			label: isEditing ? 'Unternehmen bearbeiten' : 'Unternehmen erstellen',
			onClick: handleSave,
			startIconName: isEditing ? 'EditRounded' : 'SaveOutlined',
			disabled: !canSave,
			loading: loadingCreate || loadingUpdate,
			loadingText: 'Speichern...',
			collapsedIconName: isEditing ? 'EditRounded' : 'SaveOutlined',
		} as StickyHeaderButton,
	];

	return (
		<StickyHeaderWrapper
			showWarningOnLeave={isEditing && !calledSave && !calledUpdate}
			warningOnLeave={
				'Das Unternehmen wurde bearbeitet aber Änderungen werden nicht automatisch gespeichert. Ohne speichern fortfahren?'
			}
			label="Unternehmen bearbeiten"
			isLoading={loading}
			maxContentWidth="130rem"
			buttons={buttons}
		>
			<ContentWrapper>
				<Txt variant="h6">Daten des Unternehmens</Txt>
				<TextField
					m="2rem 0"
					fullWidth
					variant="outlined"
					autoFocus
					key={name}
					defaultValue={name}
					label={'Name des Unternehmens'}
					onChange={(name) => setName(name)}
					disabled={loading}
				/>
				<UploadLogoButton
					disabled={!!logo}
					onUploaded={(filePaths) => setLogo(getImageSource(filePaths[0]))}
				>
					{'Logo hochladen'}
				</UploadLogoButton>
				{logo ? (
					<ImgWrap>
						<img
							src={logo}
							onError={() => {
								setLogo(null);
							}}
						/>
						<StyledCloseOutlined onClick={() => setLogo(null)} />
					</ImgWrap>
				) : null}
			</ContentWrapper>
		</StickyHeaderWrapper>
	);
}
