import React, { Component } from 'react';

import { sortBy } from 'lodash';
import styled from 'styled-native-components';

import { Table, Dialog, Button, Heading } from '@cinuru/components';

import UserSelectField from '../../components/UserSelectField';

import { default as gql } from 'graphql-tag';
import { withApollo } from 'react-apollo';

import WithData from '../../components/WithData';
import B2BCustomerSelectField from './B2BCustomerSelectField';

const Wrapper = styled.View`
	background-color: $background0;
	padding: 3rem 4rem;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	elevation: 2;
`;

const DescriptionWrapper = styled.View`
	margin-bottom: 4rem;
`;

const TABLE_MARGINS = [0, -0.5];
const FIELD_MARGINS = [2, -0.5];

class CinemaSelection extends React.Component {
	handleChange = async (b2bCustomers) => {
		const {
			userId,
			intiallySelectedB2BCustomers = [],
			isNewUser = false,
			onForceRerender,
		} = this.props;
		//added customers are customers, that where not part of intiallySelectedB2BCustomers Array
		const addedCustomerIds = b2bCustomers.filter((c) => !intiallySelectedB2BCustomers.includes(c));
		//Removed customers are customers, that were part of the intiallySelectedB2BCustomers but not of the new b2bCustomers array
		const removedCustomerIds = intiallySelectedB2BCustomers.filter(
			(c) => !b2bCustomers.includes(c)
		);
		await Promise.all(
			removedCustomerIds.map((b2bCustomerCinemaId) =>
				this.handleDeleteCinemaPrivilegeFromUser(userId, b2bCustomerCinemaId)
			)
		);
		if (
			removedCustomerIds.length > 0 &&
			removedCustomerIds.length === intiallySelectedB2BCustomers.length
		) {
			//The last cinema has been removed, rerender the privileges Table
			onForceRerender();
		}
		await Promise.all(
			addedCustomerIds.map((b2bCustomerCinemaId) =>
				this.handleAddCinemaPrivilegeToUser(userId, b2bCustomerCinemaId)
			)
		);
		if (isNewUser && addedCustomerIds.length > 0) {
			//After a new cinema has been added to a new admin user, close the new user panel
			//userPrivilegeTableKey is used to rerender the privileges table
			this.setState({
				...this.state,
				newUserId: null,
				showUserSearch: false,
			});
			onForceRerender();
		}
	};

	handleDeleteCinemaPrivilegeFromUser = (userId, cinemaOperatingCompanyId) => {
		return new Promise((resolve) => {
			Dialog.render({
				title: 'Berechtigung entfernen',
				description: 'Wollen Sie die Berechtigung wirklich entfernen?`',
				buttons: [
					{ label: 'ABBRECHEN' },
					{
						label: 'ENTFERNEN',
						color: '$error',
						textColor: '$background0',
						link: false,
						onPress: async () => {
							await this.props.client.mutate({
								mutation: gql`
									mutation removeAdminPrivileges($userId: ID!, $cinemaOperatingCompanyId: ID!) {
										updateUserAdminStatus(
											userId: $userId
											cinemaOperatingCompanyId: $cinemaOperatingCompanyId
											setAdminPrivilege: false
										) {
											success
											user {
												id
												name
												email
												privileges {
													belongsToCinemaOperatingCompanies {
														id
														name
													}
													rootRole
												}
											}
										}
									}
								`,
								variables: { userId, cinemaOperatingCompanyId },
							});
							resolve();
						},
					},
				],
			});
		});
	};
	handleAddCinemaPrivilegeToUser = async (userId, cinemaOperatingCompanyId) => {
		const { data, errors } = await this.props.client.mutate({
			mutation: gql`
				mutation addAdminUserPrivilege($userId: ID!, $cinemaOperatingCompanyId: ID!) {
					updateUserAdminStatus(
						userId: $userId
						cinemaOperatingCompanyId: $cinemaOperatingCompanyId
						setAdminPrivilege: true
					) {
						success
						user {
							id
							name
							email
							privileges {
								belongsToCinemaOperatingCompanies {
									id
									name
								}
								rootRole
							}
						}
					}
				}
			`,
			variables: { userId, cinemaOperatingCompanyId },
		});
		if (data && data.updateUserAdminStatus && data.updateUserAdminStatus.success) {
			Dialog.render({
				title: 'Gespeichert',
				description: 'Die Berechtigung wurde in die Datenbank eingetragen.',
			});
		} else {
			Dialog.render({
				title: 'Error',
				description: errors && errors.map(({ message }) => message).join('\n\n'),
			});
		}
	};

	render = () => {
		const { intiallySelectedB2BCustomers = [] } = this.props;
		return (
			<B2BCustomerSelectField
				multi={true}
				nMargins={FIELD_MARGINS}
				autoclose={true}
				selectedB2BCustomers={intiallySelectedB2BCustomers}
				onChange={this.handleChange}
			/>
		);
	};
}

class EditAdminUsers extends Component {
	//userPrivilegeTableKey is used to rerender the privileges table if necessary
	state = { newUserId: null, showUserSearch: false, userPrivilegeTableKey: 1 };

	handleForceRerender = () =>
		this.setState({ userPrivilegeTableKey: Math.random(), newUserId: null, showUserSearch: false });

	addNewUser = (user) => {
		this.setState({ ...this.state, newUserId: user });
	};

	render = () => (
		<Wrapper>
			<DescriptionWrapper>
				<Heading size="l" margin="4rem 0rem 1rem">
					Berechtigungen verwalten
				</Heading>
				{!this.state.showUserSearch ? (
					<Button
						onPress={() => {
							this.setState({ ...this.state, showUserSearch: true });
						}}
						label="Neuen Nutzer hinzufügen"
						margin="2rem 0rem 4rem 0rem"
					/>
				) : (
					<React.Fragment>
						<UserSelectField onChange={this.addNewUser} />
						{this.state.newUserId ? (
							<CinemaSelection
								userId={this.state.newUserId}
								isNewUser={true}
								client={this.props.client}
								onForceRerender={this.handleForceRerender}
							/>
						) : null}
					</React.Fragment>
				)}
				<WithData
					key={this.state.userPrivilegeTableKey}
					query={gql`
						{
							adminUsers {
								id
								name
								email
								privileges {
									belongsToCinemaOperatingCompanies {
										id
										name
									}
									rootRole
								}
							}
						}
					`}
				>
					{({ adminUsers }) => {
						const rows = sortBy(adminUsers, [(i) => i.email.toLowerCase()])
							.filter((u) => !u.privileges.rootRole)
							.map((u) => {
								const intiallySelectedB2BCustomers = u.privileges.belongsToCinemaOperatingCompanies.map(
									(c) => c.id
								);
								return {
									id: u.id,
									email: u.email,
									privileges: (
										<CinemaSelection
											userId={u.id}
											intiallySelectedB2BCustomers={intiallySelectedB2BCustomers}
											client={this.props.client}
											onForceRerender={this.handleForceRerender}
										/>
									),
								};
							});

						return (
							<Table
								nMargins={TABLE_MARGINS}
								// eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
								columns={[
									{ label: 'Email', key: 'email', flex: 1 },
									{ label: 'Admin Berechtigugen', key: 'privileges', flex: 3 },
								]}
								data={rows}
								pagination={false}
							/>
						);
					}}
				</WithData>
			</DescriptionWrapper>
		</Wrapper>
	);
}

export default withApollo(EditAdminUsers);
