import * as React from 'react';
import { Typography, Select, MenuItem } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import FormControlWrapper from './FormControlWrapper';
import TextField from './TextField';

type ItemType = { label: string; value: string; disabled?: boolean };

const decodePropsValue = (propsValue?: string | null): string | '' => propsValue || '';
const encodeStateValue = (stateValue: string | ''): string | null => stateValue || null;

export type SelectFieldRef = {
	validate: () => boolean;
	reset: () => void;
};

const SelectField = React.forwardRef<
	SelectFieldRef,
	{
		label: string;
		disabled?: boolean;
		onChange?: (output: string | null) => void;
		defaultValue?: string | null;
		margin?: string;
		variant?: 'outlined' | 'filled' | 'standard';
		flex?: boolean;
		nothingSelectedLabel?: string;
		items: ItemType[];
		required?: boolean;
		requiredText?: string;
		hideEmptySelectOption?: boolean;
		loading?: boolean;
	}
>(
	(
		{
			label = 'No label provided',
			disabled,
			onChange,
			defaultValue,
			margin,
			variant,
			flex,
			nothingSelectedLabel = '-',
			items,
			required = false,
			requiredText = 'Bitte wählen',
			hideEmptySelectOption,
			loading,
		},
		ref
	): JSX.Element => {
		const combinedItems: ItemType[] = React.useMemo(
			() =>
				hideEmptySelectOption ? items : [{ label: nothingSelectedLabel, value: '' }, ...items],
			[hideEmptySelectOption, items, nothingSelectedLabel]
		);

		const [selectedValue, setSelectedValue] = React.useState<string | ''>(
			decodePropsValue(defaultValue)
		);

		const [errorMessage, setErrorMessage] = React.useState<null | string>(null);

		const handleChange = React.useCallback(
			(event: SelectChangeEvent<string> | { target: { value: string } }) => {
				const newValue = event?.target?.value as string | '';
				setErrorMessage(null);
				setSelectedValue(newValue);
				onChange && onChange(encodeStateValue(newValue));
			},
			[onChange]
		);

		const renderValue = React.useCallback(
			(value: string | '') => {
				const newLabel = items.find((item) => item.value === value)?.label;
				return <Typography color="textPrimary">{newLabel}</Typography>;
			},
			[items]
		);

		const handleValidate = React.useCallback(() => {
			const newErrorMessage = !selectedValue ? requiredText : null;
			setErrorMessage(newErrorMessage);
			return Boolean(newErrorMessage);
		}, [requiredText, selectedValue]);

		React.useImperativeHandle(ref, () => ({
			validate: () => handleValidate(),
			reset: () => setSelectedValue(''),
		}));

		const handleBlur = React.useCallback(() => {
			if (required) {
				handleValidate();
			}
		}, [handleValidate, required]);

		return loading ? (
			<TextField label={label} variant={variant} isLoading />
		) : (
			<FormControlWrapper
				flex={flex}
				m={margin}
				errorMessage={errorMessage || undefined}
				variant={variant}
				label={label}
				disabled={loading || disabled}
			>
				<Select
					value={selectedValue}
					onChange={handleChange}
					renderValue={renderValue}
					disabled={loading || disabled}
					variant={variant}
					label={label}
					onBlur={handleBlur}
				>
					{combinedItems.map((item) => (
						<MenuItem key={item.value} value={item.value} disabled={item.disabled}>
							{item.label}
						</MenuItem>
					))}
				</Select>
			</FormControlWrapper>
		);
	}
);

export default React.memo(SelectField);
