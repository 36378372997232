import React from 'react';
import { useQuery } from 'react-apollo';
import client from '../apollo';
import gql from 'graphql-tag';
import _ from 'lodash';

import { ID } from 'utils/types';
import { useAllGenres } from './genre';
import { useAllAchievements } from './achievement';
import { useAllScreeningAttributes } from './screening';

/* ---------------------------------- TYPES --------------------------------- */
export type ParentStatus = 'PARENTS_ONLY' | 'NON_PARENTS_ONLY';
export type LongTermBenefitsStatus =
	| 'LONGTERMBENEFITS_RECEIVED_ONLY'
	| 'NO_LONGTERMBENEFITS_RECEIVED_ONLY';
export type BonusProgramMemberStatus =
	| 'BONUSPROGRAM_MEMBERS_ONLY'
	| 'NON_BONUSPROGRAM_MEMBERS_ONLY';
export type TargetGroupClusterTimeUnit = 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';
export type Gender = 'MALE' | 'FEMALE' | 'DIVERSE';
export type TicketGroupSize = 'LARGEROUP' | 'PAIRS' | 'SINGLE';

export type MovieAttribute = {
	id: ID;
	label: string;
};

export type Cluster = {
	id: ID;
	name: string;
	position: number;
	createdDatetime: Date;
	lastEditedDatetime: Date;
	targetGroupId: string;
	clusterSize?: number;
	ageFilter: {
		active: boolean;
		minAge: number;
		maxAge?: number;
	};
	gendersFilter: {
		active: boolean;
		genders: Gender[];
	};
	parentStatusFilter: {
		active: boolean;
		status: ParentStatus;
	};
	longTermBenefitsReceivedFilter: {
		active: boolean;
		status: LongTermBenefitsStatus;
	};
	distanceFilter: {
		active: boolean;
		zipCode: number;
		distanceInKm: number;
	};
	movieAffinityFilter: {
		active: boolean;
		movies: {
			id: ID;
			title: string;
		}[];
	};
	genreAffinityFilter: {
		active: boolean;
		genres: string[];
	};
	castOrCrewMemberAffinityFilter: {
		active: boolean;
		castOrCrewMembers: {
			id: ID;
			fullName: string;
		}[];
	};
	movieAttributesAffinityFilter: {
		active: boolean;
		movieAttributes: MovieAttribute[];
	};
	moviesSeenFilter: {
		active: boolean;
		movies: {
			id: ID;
			title: string;
		}[];
	};
	moviesNotSeenFilter: {
		active: boolean;
		movies: {
			id: ID;
			title: string;
		}[];
	};
	moviesOnWatchListFilter: {
		active: boolean;
		movies: {
			id: ID;
			title: string;
		}[];
	};
	bonusProgramMemberStatusFilter: {
		active: boolean;
		status: BonusProgramMemberStatus;
	};
	bonusPointsFilter: {
		active: boolean;
		minBonusPoints: number;
		maxBonusPoints?: number;
	};
	statusPointsFilter: {
		active: boolean;
		minStatusPoints: number;
		maxStatusPoints?: number;
	};
	statusLevelFilter: {
		active: boolean;
		minStatusLevel: number;
		maxStatusLevel?: number;
	};
	vouchersReceivedFilter: {
		active: boolean;
		vouchers: {
			id: ID;
			title: string;
		}[];
	};
	vouchersRedeemedFilter: {
		active: boolean;
		vouchers: {
			id: ID;
			title: string;
		}[];
	};
	stickersReceivedFilter: {
		active: boolean;
		stickers: {
			id: ID;
			title: string;
			description?: string;
		}[];
	};
	averageCinemaVisitsFilter: {
		active: boolean;
		minVisits: number;
		maxVisits?: number;
		timePeriod: TargetGroupClusterTimeUnit;
	};
	noCinemaVisitSinceFilter: {
		active: boolean;
		amount?: number;
		timePeriod: TargetGroupClusterTimeUnit;
	};
	cinemaVisitSinceFilter: {
		active: boolean;
		amount?: number;
		timePeriod: TargetGroupClusterTimeUnit;
	};
	averageGroupSizesFilter: {
		active: boolean;
		sizes: TicketGroupSize[];
	};
};

export type TargetGroup = {
	id: ID;
	name: string;
	createdDatetime: Date;
	lastEditedDatetime: Date;
	cinemas: {
		id: ID;
		name: string;
	}[];
	cinemaOperatingCompanies: {
		id: ID;
		targetGroupFilterConfig: {
			name: string;
			showFilter: boolean;
		}[];
		allVoucherClassesOfCinemaOperatingCompany: {
			id: ID;
			title?: string;
		}[];
	}[];
	targetGroupSize?: number;
	status: 'NOT_IN_USE' | 'IN_USE' | 'ARCHIVED';
	cluster: Cluster[];
};

export type Options = {
	genres: { id: ID; name: string }[];
	vouchers: { id: ID; title?: string }[];
	stickers: { id: ID; title: string }[];
	movieAttributes: { id: ID; label: string }[];
};

export type ClusterWithId = { [key: string]: Cluster };

/* ------------------------------ DataFragments ----------------------------- */

export const targetGroupDataFragment = gql`
	fragment targetGroupDataFragment on TargetGroup {
		id
		name
		createdDatetime
		lastEditedDatetime
		cinemas {
			id
			name
		}
		cinemaOperatingCompanies {
			id
			targetGroupFilterConfig {
				name
				showFilter
			}
			allVoucherClassesOfCinemaOperatingCompany {
				id
				title
			}
		}
		targetGroupSize
		status
		cluster {
			id
			position
			name
			createdDatetime
			lastEditedDatetime
			targetGroupId
			clusterSize
			ageFilter {
				active
				minAge
				maxAge
			}
			gendersFilter {
				active
				genders
			}
			parentStatusFilter {
				active
				status
			}
			longTermBenefitsReceivedFilter {
				active
				status
			}
			distanceFilter {
				active
				zipCode
				distanceInKm
			}
			movieAffinityFilter {
				active
				movies {
					id
					title
				}
			}
			genreAffinityFilter {
				active
				genres
			}
			castOrCrewMemberAffinityFilter {
				active
				castOrCrewMembers {
					id
					fullName
				}
			}
			movieAttributesAffinityFilter {
				active
				movieAttributes {
					id
					label
				}
			}
			moviesSeenFilter {
				active
				movies {
					id
					title
				}
			}
			moviesNotSeenFilter {
				active
				movies {
					id
					title
				}
			}
			moviesOnWatchListFilter {
				active
				movies {
					id
					title
				}
			}
			bonusProgramMemberStatusFilter {
				active
				status
			}
			bonusPointsFilter {
				active
				minBonusPoints
				maxBonusPoints
			}
			statusPointsFilter {
				active
				minStatusPoints
				maxStatusPoints
			}
			statusLevelFilter {
				active
				minStatusLevel
				maxStatusLevel
			}
			vouchersReceivedFilter {
				active
				vouchers {
					id
					title
				}
			}
			vouchersRedeemedFilter {
				active
				vouchers {
					id
					title
				}
			}
			stickersReceivedFilter {
				active
				stickers {
					id
					title
					description
				}
			}
			averageCinemaVisitsFilter {
				active
				minVisits
				maxVisits
				timePeriod
			}
			noCinemaVisitSinceFilter {
				active
				amount
				timePeriod
			}
			cinemaVisitSinceFilter {
				active
				amount
				timePeriod
			}
			averageGroupSizesFilter {
				active
				sizes
			}
		}
	}
`;

/* -------------------------- Queries And Mutations ------------------------- */

const TargetGroupQuery = gql`
	query($id: ID!) {
		targetGroup(id: $id) {
			...targetGroupDataFragment
		}
	}
	${targetGroupDataFragment}
`;
interface TargetGroupClusterTransformed extends Omit<TargetGroup, 'cluster'> {
	cluster: {
		[key: string]: Cluster;
	};
}

export const useTargetGroup = (id: ID): TargetGroupClusterTransformed | undefined => {
	const { data } = useQuery(TargetGroupQuery, { variables: { id } });
	const targetGroup = React.useMemo(
		() =>
			data?.targetGroup
				? {
						...data.targetGroup,
						cluster: _.sortBy(data.targetGroup.cluster, ['position']).reduce(
							(acc: { [key: string]: Cluster }, c: Cluster) => ({
								...acc,
								[c.id]: {
									...c,
								},
							}),
							{}
						),
				  }
				: undefined,
		[data]
	);

	return targetGroup;
};

export const useStaticTargetGroupFilters = ({
	targetGroupId,
}: {
	targetGroupId: ID;
}): Options | undefined => {
	const defaultTargetGroup = React.useMemo(
		() => ({
			id: targetGroupId,
			name: 'Neue erstellte Zielgruppe',
			cluster: {},
			cinemas: [],
			cinemaOperatingCompanies: [],
		}),
		[targetGroupId]
	);
	const targetGroup = useTargetGroup(targetGroupId) || defaultTargetGroup;

	const [selectionOptions, setSelectionOptions] = React.useState<Options | undefined>(undefined);

	const allGenres = useAllGenres();
	const allAchievements = useAllAchievements();
	const allScreeningAttributes = useAllScreeningAttributes();

	React.useEffect(() => {
		setSelectionOptions({
			genres: allGenres?.filter((g) => g.id && g.name) || [],
			vouchers:
				targetGroup.cinemaOperatingCompanies[0]?.allVoucherClassesOfCinemaOperatingCompany || [],
			stickers:
				allAchievements
					?.filter((p) => p.id && p.title)
					.map((p) => {
						return {
							id: p.id,
							title: `${p.title}${p.description ? ': "' + p.description + '"' : ''}`,
						};
					}) || [],
			movieAttributes: allScreeningAttributes?.filter((att) => att.id && att.label) || [],
		});
	}, [setSelectionOptions, allAchievements, targetGroup, allGenres, allScreeningAttributes]);

	return selectionOptions;
};

export const useAllTargetGroupsByCinemaOperatingCompanies = (
	cinemaOperatingCompanyIds?: ID[]
): undefined | TargetGroup[] => {
	const { data } = useQuery(
		gql`
			query AllTargetGroupsByCinemaOperatingCompanies($cinemaOperatingCompanyIds: [ID!]!) {
				allTargetGroupsByCinemaOperatingCompanies(
					cinemaOperatingCompanyIds: $cinemaOperatingCompanyIds
				) {
					targetGroups {
						...targetGroupDataFragment
					}
				}
			}
			${targetGroupDataFragment}
		`,
		{
			variables: {
				cinemaOperatingCompanyIds,
			},
			skip: !cinemaOperatingCompanyIds,
			fetchPolicy: 'cache-and-network',
		}
	);

	return React.useMemo(() => data?.allTargetGroupsByCinemaOperatingCompanies?.targetGroups, [
		data?.allTargetGroupsByCinemaOperatingCompanies?.targetGroups,
	]);
};

type SaveTargetGroupError = 'UNAUTHORIZED' | 'INVALID_TARGET_GROUP_ID' | 'NETWORK_ERROR';

export const saveTargetGroup = async ({
	id,
	name,
	clusters,
}: {
	id: ID;
	name: string;
	clusters: Cluster[];
}): Promise<{ error: SaveTargetGroupError; success: false } | { error: null; success: true }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation SaveTargetGroup($id: ID!, $name: String!, $cluster: [TargetGroupClusterInput!]!) {
					editTargetGroup(id: $id, name: $name, cluster: $cluster) {
						targetGroup {
							...targetGroupDataFragment
						}
					}
				}
				${targetGroupDataFragment}
			`,
			variables: { id: id, name: name, cluster: clusters }, // ...targetGroup, cluster: reducedCluster },
		});
		if (errors) {
			const error = errors[0].message;
			return { success: false, error: error as SaveTargetGroupError };
		} else {
			return { success: true, error: null };
		}
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		else throw e;
	}
};

type CreateargetGroupError = 'UNAUTHORIZED' | 'FORBIDDEN' | 'NETWORK_ERROR';

export const createTargetGroupErrorDict = {
	UNAUTHORIZED: 'Nicht autorisiert',
	FORBIDDEN: 'Nicht möglich',
	NETWORK_ERROR: 'Netzwerkfehler',
	UNEXPECTED_ERROR: 'Unerwarteter Fehler',
};

export const createTargetGroup = async (
	cinemaIds: ID[]
): Promise<
	| { error: CreateargetGroupError; success: false; targetGroupId: undefined }
	| { error: undefined; success: true; targetGroupId: ID }
> => {
	try {
		const { data, errors } = await client.mutate({
			mutation: gql`
				mutation CreateTargetGroup($name: String!, $cinemaIds: [ID!]!) {
					result: createTargetGroup(name: $name, cinemaIds: $cinemaIds) {
						targetGroup {
							id
						}
					}
				}
			`,
			variables: {
				name: 'Neue erstellte Zielgruppe',
				cinemaIds,
			},
			refetchQueries: ['AllTargetGroupsByCinemaOperatingCompanies'],
		});
		if (errors) {
			const error = errors[0].message;
			return { success: false, error: error as CreateargetGroupError, targetGroupId: undefined };
		} else {
			return { success: true, error: undefined, targetGroupId: data.result.targetGroup.id };
		}
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR', targetGroupId: undefined };
		else throw e;
	}
};

type DeleteTargetGroupsError = 'NETWORK_ERROR' | 'FORBIDDEN' | 'CANNOT_DELETE_ACTIVE_TARGET_GROUP';

export const deleteTargetGroups = async (
	targetGroupIds: ID[]
): Promise<
	{ error: DeleteTargetGroupsError; success: false } | { error: undefined; success: true }
> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation DeleteTargetGroups($ids: [ID!]!) {
					result: deleteTargetGroups(ids: $ids) {
						success
					}
				}
			`,
			variables: {
				ids: targetGroupIds,
			},
			refetchQueries: ['AllTargetGroupsByCinemaOperatingCompanies'],
		});
		if (errors) {
			const error = errors[0].message;
			return { success: false, error: error as DeleteTargetGroupsError };
		} else {
			return { success: true, error: undefined };
		}
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		else throw e;
	}
};

export const updateTargetGroupsStatus = async (
	campaignIds: ID[],
	newStatus: TargetGroup['status']
): Promise<{ error: string; success: false } | { error: undefined; success: true }> => {
	console.log('newStatus: ', newStatus);

	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation UpdateTargetGroupsStatus($ids: [ID!]!, $newStatus: UpdatedTargetGroupStatus!) {
					updateTargetGroupsStatus(ids: $ids, newStatus: $newStatus) {
						targetGroups {
							...targetGroupDataFragment
						}
					}
				}
				${targetGroupDataFragment}
			`,
			variables: {
				ids: campaignIds,
				newStatus: newStatus,
			},
		});
		if (errors) {
			const error = errors[0].message;
			return { success: false, error };
		} else {
			return { success: true, error: undefined };
		}
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		else throw e;
	}
};
