import React, { useMemo } from 'react';
import StickyHeaderWrapper, { StickyHeaderButton } from '../../components/StickyHeaderWrapper';
import Table, { RowItem } from '../../components/Table';
import { mapColumns } from '../../utils';
import { useHistory } from 'react-router';
import { useUserPrivileges } from '../../utils/user';
import { brand } from '../../consts';

const cinemaCompaniesColumns = mapColumns(
	[
		'Name',
		'Benutzer',
		'Zugriff auf das Dashboard',
		'Filmstatistiken',
		brand != 'CINFINITY-WEB' ? 'Zugriffsrecht Bonusprogramm' : null,
		'Zugriff auf Campaigning-Tools',
	].filter((item) => item != null)
);

export function CinemaOperatingCompaniesOverview() {
	const privileges = useUserPrivileges();

	const cinemaOperatingCompanies = privileges?.belongsToCinemaOperatingCompanies || [];

	const history = useHistory();

	const formatBooleanText = (value: boolean) => (value ? 'Ja' : 'Nein');
	const rows = useMemo<RowItem[]>(
		() =>
			cinemaOperatingCompanies.map((c) => ({
				id: c.id,
				data: [
					{ text: c.name },
					{
						text: c.associatedUsers?.length
							? c.associatedUsers.map((c) => c.fullName || c.email).join(', ')
							: '-',
					},
					{ text: formatBooleanText(c.accessRightDashboard) },
					{ text: formatBooleanText(c.accessRightFilmStatistics) },
					brand !== 'CINFINITY-WEB' ? { text: formatBooleanText(c.accessRightBonusProgram) } : null,
					{ text: formatBooleanText(c.accessRightCampaigning) },
					{
						buttonLabel: 'Bearbeiten',
						onPress: () => history.push(`/cinema-company/details/${c.id}`),
					},
				].filter((item) => item !== null),
				rawData: c,
			})),
		[cinemaOperatingCompanies]
	);

	const buttons = React.useMemo(
		() => [
			{
				label: 'Kinobetreiber erstellen',
				onClick: () => history.push('/cinema-company/create'),
				startIconName: 'Add',
				loading: false,
				collapsedIconName: 'Add',
			} as StickyHeaderButton,
		],
		[]
	);

	return (
		<StickyHeaderWrapper
			label="Kinobetreiber"
			maxContentWidth="130rem"
			isLoading={!privileges}
			buttons={buttons}
		>
			<Table
				defaultRowsPerPage={10}
				columns={cinemaCompaniesColumns}
				allRows={rows}
				isSearchable={true}
				isSelectable={false}
			/>
		</StickyHeaderWrapper>
	);
}
