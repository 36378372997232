import React from 'react';
import { useQuery } from 'react-apollo';
import cookies from 'js-cookie';
import gql from 'graphql-tag';

import { FILE_SERVER_ENDPOINT } from '../consts';
import client from '../apollo';
import type { ID } from '@cinuru/utils/types';

export type Image = { id: ID; url: string; datetime?: Date; cinemaOperatingCompanyId?: ID };

type SaveImageError = 'NETWORK_ERROR' | 'UNAUTHORIZED' | 'UNEXPECTED_ERROR';

const AllImagesQuery = gql`
	query AllImages {
		allImages {
			id
			datetime
			url
		}
	}
`;

async function saveImagePath(
	filePath: string
): Promise<
	| {
			error: SaveImageError;
			success: false;
			uploadedImage: undefined;
	  }
	| {
			error: null;
			success: true;
			uploadedImage: Image;
	  }
> {
	try {
		const { errors, data } = await client.mutate({
			mutation: gql`
				mutation SaveImagePath($filePath: String!) {
					saveImagePath(filePath: $filePath) {
						success
						uploadedImage {
							id
							url
							datetime
						}
					}
				}
			`,
			variables: { filePath },
			refetchQueries: ['AllImages'],
		});

		if (errors?.length) {
			const error = errors[0].message as SaveImageError;
			return { success: false, error, uploadedImage: undefined };
		} else {
			const uploadedImage = data.saveImagePath.uploadedImage;
			return { success: true, error: null, uploadedImage };
		}
	} catch (e: any) {
		if (e.networkError)
			return {
				success: false,
				error: 'NETWORK_ERROR',
				uploadedImage: undefined,
			};
		else throw e;
	}
}

async function uploadImageToCDN(file): Promise<{ filePath: string }> {
	const fileServerToken = cookies.get('_fileServerToken');
	const formData = new FormData();
	formData.append('customerImages', file);

	const res = await fetch(`${FILE_SERVER_ENDPOINT}/upload-campaign-gallery-image`, {
		method: 'POST',
		body: formData,
		headers: { fileservertoken: fileServerToken },
	});

	return await res.json();
}

export async function uploadImageToFileServer<T extends boolean>(
	file: File,
	shouldStoreInGallery?: T
): Promise<
	T extends true
		? Awaited<ReturnType<typeof saveImagePath>>
		: T extends false
		? Awaited<ReturnType<typeof uploadImageToCDN>>
		: never
>;

export async function uploadImageToFileServer(file, shouldStoreInGallery = true) {
	const { filePath } = await uploadImageToCDN(file);

	if (shouldStoreInGallery) {
		return await saveImagePath(filePath);
	} else {
		return { filePath };
	}
}

type DeleteImageError =
	| 'NETWORK_ERROR'
	| 'UNAUTHORIZED'
	| 'IMAGE_DOES_NOT_EXIST'
	| 'IMAGE_ID_DUPLICATION'
	| 'INVALID_REQUEST';

export const deleteImage = async ({
	id,
}: {
	id: ID;
}): Promise<
	| { error: DeleteImageError; success: false }
	| {
			error: null;
			success: true;
	  }
> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation DeleteImage($id: ID!) {
					deleteImage(id: $id) {
						success
					}
				}
			`,
			variables: { id },
			update: () => {
				const oldState = client.readQuery({
					query: AllImagesQuery,
				});
				const newState = {
					...oldState,
					allImages: oldState.allImages.filter((image) => image.id !== id),
				};
				client.writeQuery({ query: AllImagesQuery, data: newState });
			},
		});

		if (errors?.length) {
			const error = errors[0].message as DeleteImageError;
			return { success: false, error };
		} else {
			return { success: true, error: null };
		}
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		else throw e;
	}
};

export const useUploadedImages = ():
	| undefined
	| {
			id: ID;
			url: string;
			datetime: Date;
	  }[] => {
	const { data } = useQuery(
		gql`
			query AllImages {
				allImages {
					id
					datetime
					url
				}
			}
		`,
		{}
	);
	return React.useMemo(() => data?.allImages, [data?.allImages]);
};

export const getImageSource = (relativePath: string) =>
	`${FILE_SERVER_ENDPOINT}/public/${relativePath}`;

const canvas = document.createElement('canvas');

export async function resizeImage(file: File, maxSize: number) {
	const img = new Image();
	const src = URL.createObjectURL(file);
	img.src = src;
	await new Promise((resolve) => (img.onload = resolve));

	const ctx = canvas.getContext('2d')!;

	// Crop to square from center
	const size = Math.min(img.width, img.height);
	const sx = (img.width - size) / 2;
	const sy = (img.height - size) / 2;

	canvas.width = maxSize;
	canvas.height = maxSize;
	ctx.drawImage(img, 0, 0, maxSize, maxSize);

	const newFile = await new Promise<File>((resolve) =>
		canvas.toBlob((blob) => resolve(new File([blob!], file.name, { type: file.type })))
	);

	return newFile;
}
