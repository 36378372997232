import React, { useMemo } from 'react';
import StickyHeaderWrapper from '../../components/StickyHeaderWrapper';
import DatetimeRangeSelectForm from '../../components/DatetimeRangeSelectForm';
import SectionWrapper2 from '../../components/SectionWrapper2';
import Txt from '../../components/Txt';
import LoadingBox from '../../components/LoadingBox';
import moment from 'moment';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import Table, { RowItem } from '../../components/Table';
import { map } from 'lodash';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../../consts';
import { dateToString } from '../../utils/time';

const MissedPayoutsQuery = gql`
	query MissedPayouts($from: Date, $to: Date) {
		missedPayouts(filter: { from: $from, to: $to }) {
			user {
				id
				fullName
				email
			}
			subscription {
				id
				paidUntil: payedUntil
			}
		}
	}
`;

type MissedPayoutsQueryType = {
	missedPayouts: Array<{
		user: {
			id: string;
			fullName: string;
			email: string;
		};
		subscription: {
			id: string;
			paidUntil: string;
		};
	}>;
};

const columns = map(['Nutzername', 'Email', 'Fehlgeschlagenen Zahlung'], (label, id) => ({
	label,
	id,
}));

export function MissedPaymentsOverview() {
	const [dateFrom, setDateFrom] = React.useState<string | undefined>(() =>
		dateToString(moment().subtract(2, 'years').toDate())
	);
	const [dateTo, setDateTo] = React.useState<string | undefined>(() =>
		dateToString(moment().toDate())
	);

	const { data, error, loading } = useQuery<MissedPayoutsQueryType>(MissedPayoutsQuery, {
		variables: {
			from: dateToString(dateFrom),
			to: dateToString(dateTo),
		},
		fetchPolicy: 'cache-and-network',
	});

	const rows = useMemo<RowItem[]>(
		() =>
			data?.missedPayouts?.map((mp) => ({
				id: mp.subscription.id,
				data: [
					{ text: mp.user.fullName || '-' },
					{ text: mp.user.email },
					{ text: mp.subscription.paidUntil },
				],
				rawData: mp,
			})) ?? [],
		[data]
	);

	return (
		<StickyHeaderWrapper label="Mahnwesen" maxContentWidth="130rem">
			<DatetimeRangeSelectForm
				label="Zeitraum wählen"
				defaultStartDate={dateFrom}
				defaultEndDate={dateTo}
				onChangeStartDate={setDateFrom}
				onChangeEndDate={setDateTo}
				dateOnly={true}
				hideCampaignEndTypeControls={true}
			/>
			<SectionWrapper2>
				{loading ? (
					<LoadingBox />
				) : error ? (
					<Txt color="error">{error.message}</Txt>
				) : (
					<Table
						defaultRowsPerPage={10}
						columns={columns}
						allRows={rows}
						isSelectable={false}
						isSearchable={false}
					/>
				)}
			</SectionWrapper2>
		</StickyHeaderWrapper>
	);
}
